import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/FooterSection";
import "./IpekPage13.css";

const IpekPage13 = () => {
  const navigate = useNavigate();

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIconClick = useCallback(() => {
    navigate("/ipekpage13");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/ipekpage11");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="ipek-page-1-2">
      <img className="ipek-page-1-2-child" alt="" src="/group-1381411@2x.png" />
      <div className="component-1-12">
        <div className="sayfa-1213">Sayfa: 12/13</div>
        <img
          className="component-1-12-child"
          alt=""
          src="/group-183982@2x.png"
        />
      </div>
      <FooterSection
        group81="/group-811@2x.png"
        group209="/group-2095@2x.png"
        group18205="/group-1820521@2x.png"
        group18222="/group-1822231@2x.png"
        path18813IconHeight="calc(100% - 31.3px)"
        onProfilClick={onProfilClick}
      />
      <img
        className="ipek-page-1-2-item"
        alt=""
        src="/group-1974231@2x.png"
        onClick={onGroupIconClick}
      />
      <img
        className="ipek-page-1-2-inner"
        alt=""
        src="/group-197434@2x.png"
        onClick={onGroupIcon1Click}
      />
      <div className="sonraki-sayfa11">Sonraki Sayfa</div>
      <div className="nceki-sayfa10">Önceki Sayfa</div>
      <div className="sesi-a11">Sesi Aç</div>
      <div className="szlk11">Sözlük</div>
      <div className="ipek-page-1-2-inner1">
        <div className="group-parent21">
          <img className="group-child52" alt="" src="/group-257.svg" />
          <div className="group-parent21">
            <div className="group-parent21">
              <img className="group-child53" alt="" src="/group-262.svg" />
              <img className="path-397-icon11" alt="" src="/path-397.svg" />
              <img className="group-child54" alt="" src="/group-265.svg" />
              <img className="path-399-icon11" alt="" src="/path-399.svg" />
              <img className="path-400-icon11" alt="" src="/path-400.svg" />
              <img className="path-401-icon11" alt="" src="/path-401.svg" />
              <img
                className="group-child55"
                alt=""
                src="/rectangle-141@2x.png"
              />
              <img
                className="group-child56"
                alt=""
                src="/rectangle-142@2x.png"
              />
              <img className="path-402-icon11" alt="" src="/path-402.svg" />
              <img className="path-403-icon11" alt="" src="/path-403.svg" />
              <img className="path-404-icon11" alt="" src="/path-404.svg" />
              <audio className="path-40511" autoPlay controls>
                <source src="https://mobil.okumanya.com.tr/voice/ipek/ipek-ses-12.mp3" />
              </audio>
              <img className="path-406-icon11" alt="" src="/path-406.svg" />
              <img className="path-407-icon11" alt="" src="/path-407.svg" />
            </div>
          </div>
        </div>
      </div>
      <img className="ipek-page-1-2-child1" alt="" src="/group-197452@2x.png" />
      <div className="path-20190-parent7">
        <img className="path-20190-icon9" alt="" src="/path-20190.svg" />
        <div
          className="ama-pek-yalan"
          data-animate-on-scroll
        >{`Ama İpek yalan söyleyen insanlardan hiç hoşlanmaz. Hem de hiç hoşlanmaz. Bence yalan söyleyen insanların yalan söyledikleri anlaşılıyor. Ve bu gerçekten çok sinir bozucu. Bence kimse yalan söylememeli. `}</div>
      </div>
    </div>
  );
};

export default IpekPage13;
