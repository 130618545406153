import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/FooterSection";
import "./IpekPage11.css";

const IpekPage11 = () => {
  const navigate = useNavigate();

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIconClick = useCallback(() => {
    navigate("/ipekpage11");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/ipekpage9");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="ipek-page-1-0">
      <img className="ipek-page-1-0-child" alt="" src="/group-138149@2x.png" />
      <div className="component-1-10">
        <div className="sayfa-1013">Sayfa: 10/13</div>
        <img
          className="component-1-10-child"
          alt=""
          src="/group-183982@2x.png"
        />
      </div>
      <FooterSection
        group81="/group-81@2x.png"
        group209="/group-20941@2x.png"
        group18205="/group-1820521@2x.png"
        group18222="/group-1822231@2x.png"
        path18813IconHeight="calc(100% - 31.3px)"
        onProfilClick={onProfilClick}
      />
      <img
        className="ipek-page-1-0-item"
        alt=""
        src="/group-1974231@2x.png"
        onClick={onGroupIconClick}
      />
      <img
        className="ipek-page-1-0-inner"
        alt=""
        src="/group-197432@2x.png"
        onClick={onGroupIcon1Click}
      />
      <div className="sonraki-sayfa9">Sonraki Sayfa</div>
      <div className="nceki-sayfa8">Önceki Sayfa</div>
      <div className="sesi-a9">Sesi Aç</div>
      <div className="szlk9">Sözlük</div>
      <div className="ipek-page-1-0-inner1">
        <div className="group-parent17">
          <img className="group-child42" alt="" src="/group-257.svg" />
          <div className="group-parent17">
            <div className="group-parent17">
              <img className="group-child43" alt="" src="/group-262.svg" />
              <img className="path-397-icon9" alt="" src="/path-397.svg" />
              <img className="group-child44" alt="" src="/group-265.svg" />
              <img className="path-399-icon9" alt="" src="/path-399.svg" />
              <img className="path-400-icon9" alt="" src="/path-400.svg" />
              <img className="path-401-icon9" alt="" src="/path-401.svg" />
              <img
                className="group-child45"
                alt=""
                src="/rectangle-141@2x.png"
              />
              <img
                className="group-child46"
                alt=""
                src="/rectangle-142@2x.png"
              />
              <img className="path-402-icon9" alt="" src="/path-402.svg" />
              <img className="path-403-icon9" alt="" src="/path-403.svg" />
              <img className="path-404-icon9" alt="" src="/path-404.svg" />
              <audio className="path-4059" autoPlay controls>
                <source src="https://mobil.okumanya.com.tr/voice/ipek/ipek-ses-10.mp3" />
              </audio>
              <img className="path-406-icon9" alt="" src="/path-406.svg" />
              <img className="path-407-icon9" alt="" src="/path-407.svg" />
            </div>
          </div>
        </div>
      </div>
      <img className="ipek-page-1-0-child1" alt="" src="/group-197452@2x.png" />
      <div className="path-20190-parent5">
        <img className="path-20190-icon7" alt="" src="/path-20190.svg" />
        <div
          className="ama-pek-yksek"
          data-animate-on-scroll
        >{`Ama İpek, yüksek sesle konuşan yetişkinlerden hiç hoşlanmaz. İşte buna dayanamıyorum. Bazı yetişkinler kendini ne sanıyor? Neden yüksek sesle bağırıyorlar? Birileri onlara acilen çocukların gayet iyi duyabildiklerini söylemeli. `}</div>
      </div>
    </div>
  );
};

export default IpekPage11;
