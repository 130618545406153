import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/FooterSection";
import "./IpekPage.css";

const IpekPage = () => {
  const navigate = useNavigate();

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onGroupIconClick = useCallback(() => {
    navigate("/ipekpage2");
  }, [navigate]);

  return (
    <div className="ipek-page-1">
      <img className="ipek-page-1-child" alt="" src="/group-13814@2x.png" />
      <div className="component-1-1">
        <div className="sayfa-113">Sayfa: 1/13</div>
        <img className="component-1-1-child" alt="" src="/group-18398@2x.png" />
      </div>
      <FooterSection
        group81="/group-81@2x.png"
        group209="/group-20911@2x.png"
        group18205="/group-1820511@2x.png"
        group18222="/group-1822211@2x.png"
        onProfilClick={onProfilClick}
      />
      <div className="merhaba-ben-pek-container" data-animate-on-scroll>
        <p className="merhaba-ben-pek">{`Merhaba, ben İpek! `}</p>
        <p className="merhaba-ben-pek">{`Bazen kimsenin beni anlamadığını düşünüyorum. `}</p>
        <p className="merhaba-ben-pek">{`Bu yüzden size “İpek’i Tanıma Rehberi” hazırladım. `}</p>
      </div>
      <img
        className="ipek-page-1-item"
        alt=""
        src="/group-197425@2x.png"
        onClick={onGroupIconClick}
      />
      <div className="sonraki-sayfa">Sonraki Sayfa</div>
      <div className="sesi-a">Sesi Aç</div>
      <div className="szlk">Sözlük</div>
      <div className="ipek-page-1-inner">
        <div className="group-parent">
          <img className="group-child" alt="" src="/group-257.svg" />
          <div className="group-parent">
            <div className="group-parent">
              <img className="group-item" alt="" src="/group-262.svg" />
              <img className="path-397-icon" alt="" src="/path-397.svg" />
              <img className="group-inner" alt="" src="/group-265.svg" />
              <img className="path-399-icon" alt="" src="/path-399.svg" />
              <img className="path-400-icon" alt="" src="/path-400.svg" />
              <img className="path-401-icon" alt="" src="/path-401.svg" />
              <img
                className="rectangle-icon"
                alt=""
                src="/rectangle-141@2x.png"
              />
              <img
                className="group-child1"
                alt=""
                src="/rectangle-142@2x.png"
              />
              <img className="path-402-icon" alt="" src="/path-402.svg" />
              <img className="path-403-icon" alt="" src="/path-403.svg" />
              <img className="path-404-icon" alt="" src="/path-404.svg" />
              <audio className="path-405" autoPlay controls>
                <source src="https://mobil.okumanya.com.tr/voice/ipek/ipek-ses-1.mp3" />
              </audio>
              <img className="path-406-icon" alt="" src="/path-406.svg" />
              <img className="path-407-icon" alt="" src="/path-407.svg" />
            </div>
          </div>
        </div>
      </div>
      <img className="group-icon" alt="" src="/group-19745@2x.png" />
    </div>
  );
};

export default IpekPage;
