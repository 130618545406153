import "./ScrollGroup.css";

const ScrollGroup = ({ className = "" }) => {
  return (
    <div className={`scroll-group-8 ${className}`}>
      <div className="image-50-parent">
        <img className="image-50-icon2" alt="" src="/image-50@2x.png" />
        <div className="lorem-ipsum-dolor1">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. In auctor mi ut risus maximus volutpat. Aenean dapibus molestie diam id feugiat. `}</div>
        <div className="puanm">Puanım:</div>
        <div className="asset-1300x-8-parent">
          <img
            className="asset-1300x-8-icon"
            alt=""
            src="/asset-1300x8@2x.png"
          />
          <img
            className="asset-1300x-8-icon1"
            alt=""
            src="/asset-1300x81@2x.png"
          />
          <img
            className="asset-1300x-8-icon2"
            alt=""
            src="/asset-1300x8@2x.png"
          />
          <img
            className="asset-3300x-8-icon"
            alt=""
            src="/asset-3300x8@2x.png"
          />
          <img
            className="asset-3300x-8-icon1"
            alt=""
            src="/asset-3300x81@2x.png"
          />
        </div>
      </div>
      <div className="lorem-ipsum-dolor-sit-amet-co-parent">
        <div className="lorem-ipsum-dolor2">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. In auctor mi ut risus maximus volutpat. Aenean dapibus molestie diam id feugiat. `}</div>
        <div className="puanm1">Puanım:</div>
        <div className="asset-1300x-8-group">
          <img
            className="asset-1300x-8-icon3"
            alt=""
            src="/asset-1300x82@2x.png"
          />
          <img
            className="asset-1300x-8-icon4"
            alt=""
            src="/asset-1300x83@2x.png"
          />
          <img
            className="asset-1300x-8-icon5"
            alt=""
            src="/asset-1300x84@2x.png"
          />
          <img
            className="asset-3300x-8-icon2"
            alt=""
            src="/asset-3300x82@2x.png"
          />
          <img
            className="asset-3300x-8-icon3"
            alt=""
            src="/asset-3300x83@2x.png"
          />
        </div>
        <img className="image-48-icon2" alt="" src="/image-48@2x.png" />
      </div>
      <div className="image-50-group">
        <img className="image-48-icon2" alt="" src="/image-501@2x.png" />
        <div className="lorem-ipsum-dolor2">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. In auctor mi ut risus maximus volutpat. Aenean dapibus molestie diam id feugiat. `}</div>
        <div className="puanm1">Puanım:</div>
        <div className="asset-1300x-8-group">
          <img
            className="asset-1300x-8-icon3"
            alt=""
            src="/asset-1300x82@2x.png"
          />
          <img
            className="asset-1300x-8-icon4"
            alt=""
            src="/asset-1300x83@2x.png"
          />
          <img
            className="asset-1300x-8-icon5"
            alt=""
            src="/asset-1300x84@2x.png"
          />
          <img
            className="asset-3300x-8-icon2"
            alt=""
            src="/asset-3300x82@2x.png"
          />
          <img
            className="asset-3300x-8-icon3"
            alt=""
            src="/asset-3300x83@2x.png"
          />
        </div>
      </div>
    </div>
  );
};

ScrollGroup.propTypes = {
  className: PropTypes.string,
};

export default ScrollGroup;
