import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Aile11.css";

const Aile11 = () => {
  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/aile113");
  }, [navigate]);

  const onGroupIcon2Click = useCallback(() => {
    navigate("/aile111");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="aile-112">
      <img className="aile-112-icon" alt="" src="/aile112@2x.png" />
      <div className="component-1-25">
        <div className="sayfa-1214">Sayfa: 12/14</div>
        <img
          className="component-1-25-child"
          alt=""
          src="/group-183982@2x.png"
        />
      </div>
      <div className="aile-112-inner">
        <section className="path-18812-parent5" id="menu-bar">
          <img className="path-18812-icon8" alt="" src="/path-18812.svg" />
          <img className="path-18813-icon8" alt="" src="/path-188131.svg" />
          <div className="group-parent34" onClick={onGroupContainerClick}>
            <img className="group-child96" alt="" src="/group-71.svg" />
            <img className="group-child97" alt="" src="/group-811@2x.png" />
          </div>
          <div className="ana-sayfa8">Ana Sayfa</div>
          <a className="profil8" onClick={onProfilClick}>
            <img className="group-child97" alt="" src="/group-2093@2x.png" />
          </a>
          <img className="group-child98" alt="" src="/group-182051@2x.png" />
          <img className="group-child99" alt="" src="/group-182222@2x.png" />
        </section>
      </div>
      <img
        className="aile-112-child"
        alt=""
        src="/group-197423@2x.png"
        onClick={onGroupIcon1Click}
      />
      <img
        className="aile-112-item"
        alt=""
        src="/group-197434@2x.png"
        onClick={onGroupIcon2Click}
      />
      <div className="sonraki-sayfa23">Sonraki Sayfa</div>
      <div className="nceki-sayfa22">Önceki Sayfa</div>
      <div className="sesi-a24">Sesi Aç</div>
      <div className="szlk24">Sözlük</div>
      <img className="aile-112-child1" alt="" src="/group-19744@2x.png" />
      <img className="aile-112-child2" alt="" src="/group-197452@2x.png" />
      <div className="path-20190-parent20">
        <img className="path-20190-icon22" alt="" src="/path-20190.svg" />
        <div className="te-benim-ailem" data-animate-on-scroll>
          İşte benim ailem. Annem, babam, abim, ablam ve dedem… Biraz
          kalabalığız değil mi? Evet, kalabalığız. Bu öyle güzel bir şey ki!
          Hangi kapıyı açsanız birisi çıkıyor karşınıza. Kendinizi hiç yalnız
          hissetmiyorsunuz. Hiç sıkılmıyorsunuz. Hepsini öyle çok seviyorum ki!
        </div>
      </div>
    </div>
  );
};

export default Aile11;
