import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Aile6.css";

const Aile6 = () => {
  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/aile18");
  }, [navigate]);

  const onGroupIcon2Click = useCallback(() => {
    navigate("/aile16");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="aile-17">
      <img className="aile-17-icon" alt="" src="/aile17@2x.png" />
      <div className="component-1-20">
        <div className="sayfa-714">Sayfa: 7/14</div>
        <img
          className="component-1-20-child"
          alt=""
          src="/group-183981@2x.png"
        />
      </div>
      <div className="aile-17-inner">
        <section className="path-18812-parent1" id="menu-bar">
          <img className="path-18812-icon4" alt="" src="/path-18812.svg" />
          <img className="path-18813-icon4" alt="" src="/path-188131.svg" />
          <div className="group-parent30" onClick={onGroupContainerClick}>
            <img className="group-child80" alt="" src="/group-71.svg" />
            <img className="group-child81" alt="" src="/group-81@2x.png" />
          </div>
          <div className="ana-sayfa4">Ana Sayfa</div>
          <a className="profil4" onClick={onProfilClick}>
            <img className="group-child81" alt="" src="/group-2091@2x.png" />
          </a>
          <img className="group-child82" alt="" src="/group-18205@2x.png" />
          <img className="group-child83" alt="" src="/group-182221@2x.png" />
        </section>
      </div>
      <img
        className="aile-17-child"
        alt=""
        src="/group-197421@2x.png"
        onClick={onGroupIcon1Click}
      />
      <img
        className="aile-17-item"
        alt=""
        src="/group-197431@2x.png"
        onClick={onGroupIcon2Click}
      />
      <div className="sonraki-sayfa18">Sonraki Sayfa</div>
      <div className="nceki-sayfa17">Önceki Sayfa</div>
      <div className="sesi-a19">Sesi Aç</div>
      <div className="szlk19">Sözlük</div>
      <img className="aile-17-child1" alt="" src="/group-19744@2x.png" />
      <img className="aile-17-child2" alt="" src="/group-197451@2x.png" />
      <div className="path-20190-parent15">
        <img className="path-20190-icon17" alt="" src="/path-20190.svg" />
        <div className="hep-byle-cmlelerde-container" data-animate-on-scroll>
          <p className="hep-byle-cmlelerde">{`Hep böyle cümlelerde geçer adı. Futbolu çok seviyor. Sanırım bu nedenle gördüğü her şeyi futbol topu sanıyor. Çünkü inanır mısınız ne bulursa tekmeliyor. Babam bazen çok kızıyor ona. `}</p>
          <p className="hep-byle-cmlelerde">
            “Azıcık otur.” diyor. O da azıcık oturuyor. Ama gerçekten azıcık…
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aile6;
