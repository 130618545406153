import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Aile4.css";

const Aile4 = () => {
  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/aile16");
  }, [navigate]);

  const onGroupIcon2Click = useCallback(() => {
    navigate("/aile14");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="aile-15">
      <img className="aile-15-icon" alt="" src="/aile15@2x.png" />
      <div className="component-1-18">
        <div className="sayfa-514">Sayfa: 5/14</div>
        <img
          className="component-1-18-child"
          alt=""
          src="/group-183981@2x.png"
        />
      </div>
      <div className="aile-15-inner">
        <section className="group-section" id="menu-bar">
          <img className="path-18812-icon3" alt="" src="/path-18812.svg" />
          <img className="path-18813-icon3" alt="" src="/path-188131.svg" />
          <div className="group-parent29" onClick={onGroupContainerClick}>
            <img className="group-child76" alt="" src="/group-71.svg" />
            <img className="group-child77" alt="" src="/group-81@2x.png" />
          </div>
          <div className="ana-sayfa3">Ana Sayfa</div>
          <a className="profil3" onClick={onProfilClick}>
            <img className="group-child77" alt="" src="/group-2091@2x.png" />
          </a>
          <img className="group-child78" alt="" src="/group-18205@2x.png" />
          <img className="group-child79" alt="" src="/group-182221@2x.png" />
        </section>
      </div>
      <img
        className="aile-15-child"
        alt=""
        src="/group-197421@2x.png"
        onClick={onGroupIcon1Click}
      />
      <img
        className="aile-15-item"
        alt=""
        src="/group-197431@2x.png"
        onClick={onGroupIcon2Click}
      />
      <div className="sonraki-sayfa16">Sonraki Sayfa</div>
      <div className="nceki-sayfa15">Önceki Sayfa</div>
      <div className="sesi-a17">Sesi Aç</div>
      <div className="szlk17">Sözlük</div>
      <img className="aile-15-child1" alt="" src="/group-19744@2x.png" />
      <img className="aile-15-child2" alt="" src="/group-197451@2x.png" />
      <div className="path-20190-parent13">
        <img className="path-20190-icon15" alt="" src="/path-20190.svg" />
        <div className="ablam-on-yanda-container" data-animate-on-scroll>
          <p className="ablam-on-yanda">
            Ablam on yaşında, adı Ada. Upuzun, kıpkızıl saçları var. Okulda
            herkes 'Kızıl Ada' der ona. Kime sorsanız gösterir. Büyünce astronot
            olmak istediğini söylüyor. Bir uzay gemisiyle tek başına Ay’a
            gidecekmiş.Babam “Orada hayat yok!” diyor. Ablam “Ben büyüyene kadar
            belki bulunur!” diye cevap veriyor.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aile4;
