import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Aile1.css";

const Aile1 = () => {
  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/aile13");
  }, [navigate]);

  const onGroupIcon2Click = useCallback(() => {
    navigate("/aile11");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="aile-12">
      <img className="aile-12-icon" alt="" src="/aile12@2x.png" />
      <div className="component-1-15">
        <div className="sayfa-214">Sayfa: 2/14</div>
        <img
          className="component-1-15-child"
          alt=""
          src="/group-18398@2x.png"
        />
      </div>
      <div className="aile-12-inner">
        <section className="path-18812-parent" id="menu-bar">
          <img className="path-18812-icon" alt="" src="/path-18812.svg" />
          <img className="path-18813-icon" alt="" src="/path-188131.svg" />
          <div className="group-parent26" onClick={onGroupContainerClick}>
            <img className="group-child64" alt="" src="/group-71.svg" />
            <img className="profil-child" alt="" src="/group-81@2x.png" />
          </div>
          <div className="ana-sayfa">Ana Sayfa</div>
          <a className="profil" onClick={onProfilClick}>
            <img className="profil-child" alt="" src="/group-209@2x.png" />
          </a>
          <img className="group-child66" alt="" src="/group-18205@2x.png" />
          <img className="group-child67" alt="" src="/group-18222@2x.png" />
        </section>
      </div>
      <img
        className="aile-12-child"
        alt=""
        src="/group-19742@2x.png"
        onClick={onGroupIcon1Click}
      />
      <img
        className="aile-12-item"
        alt=""
        src="/group-19743@2x.png"
        onClick={onGroupIcon2Click}
      />
      <div className="sonraki-sayfa13">Sonraki Sayfa</div>
      <div className="nceki-sayfa12">Önceki Sayfa</div>
      <div className="sesi-a14">Sesi Aç</div>
      <div className="szlk14">Sözlük</div>
      <img className="aile-12-child1" alt="" src="/group-19744@2x.png" />
      <img className="aile-12-child2" alt="" src="/group-19745@2x.png" />
      <div className="path-20190-parent10">
        <img className="path-20190-icon12" alt="" src="/path-20190.svg" />
        <div className="evet-atlas-sen-container" data-animate-on-scroll>
          <p className="evet-atlas-sen">
            “Evet Atlas. Sen kocaman bir çocuksun.” diyeceksiniz. Büyüyünce ne
            olmak istediğimi merak ettiğinizi biliyorum. Sanki yeterince büyük
            değilmişim gibi… Büyüyünce, yani sizin düşündüğünüz kadar büyüyünce
            ressam olacağım. Aslında şimdiden olmaya başladım bile. Çünkü
            neredeyse her gün resim çiziyorum.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aile1;
