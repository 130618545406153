import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/FooterSection";
import "./Aile9.css";

const Aile9 = () => {
  const navigate = useNavigate();

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIconClick = useCallback(() => {
    navigate("/aile111");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/aile19");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="aile-110">
      <img className="aile-110-icon" alt="" src="/aile110@2x.png" />
      <div className="component-1-23">
        <div className="sayfa-1014">Sayfa: 10/14</div>
        <img
          className="component-1-23-child"
          alt=""
          src="/group-183982@2x.png"
        />
      </div>
      <FooterSection
        group81="/group-81@2x.png"
        group209="/group-2092@2x.png"
        group18205="/group-182051@2x.png"
        group18222="/group-182222@2x.png"
        path18813IconHeight="calc(100% - 31.2px)"
        onProfilClick={onProfilClick}
      />
      <img
        className="aile-110-child"
        alt=""
        src="/group-197423@2x.png"
        onClick={onGroupIconClick}
      />
      <img
        className="aile-110-item"
        alt=""
        src="/group-197432@2x.png"
        onClick={onGroupIcon1Click}
      />
      <div className="sonraki-sayfa21">Sonraki Sayfa</div>
      <div className="nceki-sayfa20">Önceki Sayfa</div>
      <div className="sesi-a22">Sesi Aç</div>
      <div className="szlk22">Sözlük</div>
      <img className="aile-110-inner" alt="" src="/group-19744@2x.png" />
      <img className="aile-110-child1" alt="" src="/group-197452@2x.png" />
      <div className="path-20190-parent18">
        <img className="path-20190-icon20" alt="" src="/path-20190.svg" />
        <div className="dedeme-gelirsek-dedem-container" data-animate-on-scroll>
          <p className="dedeme-gelirsek-dedem">
            Dedeme gelirsek… Dedem o kadar yaşlı ki… Yüzünde sonsuz bin kırışık
            var. Evet, yanlış duymadınız. Sonsuz bin! Onları saymak yedi tane üç
            yüz altmış beşi toplamaktan bile zor.
          </p>
          <p className="her-krn-hikyesi">
            {" "}
            “Her kırışığın hikâyesi var.” diyor sesi titreyerek dedem. “Hepsini
            anlatacağım sana torunum.”
          </p>
          <p className="dedeme-gelirsek-dedem">{`Ne kadar heyecanlı! `}</p>
        </div>
      </div>
    </div>
  );
};

export default Aile9;
