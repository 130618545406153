import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Aile10.css";

const Aile10 = () => {
  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/aile112");
  }, [navigate]);

  const onGroupIcon2Click = useCallback(() => {
    navigate("/aile110");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="aile-111">
      <img className="aile-111-icon" alt="" src="/aile111@2x.png" />
      <div className="component-1-24">
        <div className="sayfa-1114">Sayfa: 11/14</div>
        <img
          className="component-1-24-child"
          alt=""
          src="/group-183983@2x.png"
        />
      </div>
      <div className="aile-111-inner">
        <section className="path-18812-parent4" id="menu-bar">
          <img className="path-18812-icon7" alt="" src="/path-18812.svg" />
          <img className="path-18813-icon7" alt="" src="/path-188131.svg" />
          <div className="group-parent33" onClick={onGroupContainerClick}>
            <img className="group-child92" alt="" src="/group-71.svg" />
            <img className="group-child93" alt="" src="/group-811@2x.png" />
          </div>
          <div className="ana-sayfa7">Ana Sayfa</div>
          <a className="profil7" onClick={onProfilClick}>
            <img className="group-child93" alt="" src="/group-2092@2x.png" />
          </a>
          <img className="group-child94" alt="" src="/group-182051@2x.png" />
          <img className="group-child95" alt="" src="/group-182223@2x.png" />
        </section>
      </div>
      <img
        className="aile-111-child"
        alt=""
        src="/group-197424@2x.png"
        onClick={onGroupIcon1Click}
      />
      <img
        className="aile-111-item"
        alt=""
        src="/group-197433@2x.png"
        onClick={onGroupIcon2Click}
      />
      <div className="sonraki-sayfa22">Sonraki Sayfa</div>
      <div className="nceki-sayfa21">Önceki Sayfa</div>
      <div className="sesi-a23">Sesi Aç</div>
      <div className="szlk23">Sözlük</div>
      <img className="aile-111-child1" alt="" src="/group-19744@2x.png" />
      <img className="aile-111-child2" alt="" src="/group-197452@2x.png" />
      <div className="path-20190-parent19">
        <img className="path-20190-icon21" alt="" src="/path-20190.svg" />
        <div className="dnsenize-dedemin-sonsuz" data-animate-on-scroll>
          Düşünsenize dedemin sonsuz bin hikâyesi var. Ama hepsini nasıl
          hatırlayacak ki? Olsun. Hatırladıklarını anlatsa yeter. Sizin
          dedenizin de bu kadar çok hikâyesi var mı? Hemen gidip sorun bence! Ya
          da telefonla arayın! Ama tabii önce “Nasılsın, iyi misin dedeciğim?”
          diye sormayı unutmayın.
        </div>
      </div>
    </div>
  );
};

export default Aile10;
