import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Aile7.css";

const Aile7 = () => {
  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/aile19");
  }, [navigate]);

  const onGroupIcon2Click = useCallback(() => {
    navigate("/aile17");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="aile-18">
      <img className="aile-18-icon" alt="" src="/aile18@2x.png" />
      <div className="component-1-21">
        <div className="sayfa-814">Sayfa: 8/14</div>
        <img
          className="component-1-21-child"
          alt=""
          src="/group-183981@2x.png"
        />
      </div>
      <div className="aile-18-inner">
        <section className="path-18812-parent2" id="menu-bar">
          <img className="path-18812-icon5" alt="" src="/path-18812.svg" />
          <img className="path-18813-icon5" alt="" src="/path-188131.svg" />
          <div className="group-parent31" onClick={onGroupContainerClick}>
            <img className="group-child84" alt="" src="/group-71.svg" />
            <img className="group-child85" alt="" src="/group-81@2x.png" />
          </div>
          <div className="ana-sayfa5">Ana Sayfa</div>
          <a className="profil5" onClick={onProfilClick}>
            <img className="group-child85" alt="" src="/group-2091@2x.png" />
          </a>
          <img className="group-child86" alt="" src="/group-18205@2x.png" />
          <img className="group-child87" alt="" src="/group-182222@2x.png" />
        </section>
      </div>
      <img
        className="aile-18-child"
        alt=""
        src="/group-197422@2x.png"
        onClick={onGroupIcon1Click}
      />
      <img
        className="aile-18-item"
        alt=""
        src="/group-197431@2x.png"
        onClick={onGroupIcon2Click}
      />
      <div className="sonraki-sayfa19">Sonraki Sayfa</div>
      <div className="nceki-sayfa18">Önceki Sayfa</div>
      <div className="sesi-a20">Sesi Aç</div>
      <div className="szlk20">Sözlük</div>
      <img className="aile-18-child1" alt="" src="/group-19744@2x.png" />
      <img className="aile-18-child2" alt="" src="/group-197451@2x.png" />
      <div className="path-20190-parent16">
        <img className="path-20190-icon18" alt="" src="/path-20190.svg" />
        <div className="annem-birlikte-bir-container" data-animate-on-scroll>
          <p className="annem-birlikte-bir">
            Annem “Birlikte bir şeyler seyredelim oğlum.” diyor abime. Sonra
            biraz pişman oluyor. Pek beğenmiyor abimin seçtiği filmleri. Bir
            defasında şöyle dedi hatta: “Tunç! Bu filmler korkunç!”
          </p>
          <p className="annem-birlikte-bir">
            {" "}
            Ablamla annemin taklidini yapıp kızdırıyoruz abimi. “Tunç! Bu
            filmler korkunç!”
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aile7;
