import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./FooterSection1.css";

const FooterSection1 = ({ className = "" }) => {
  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onProfil1Click = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  return (
    <div className={`yapayzeka-inner ${className}`}>
      <section className="path-18812-parent7" id="menu-bar">
        <img className="path-18812-icon10" alt="" src="/path-18812.svg" />
        <img className="path-18813-icon10" alt="" src="/path-188131.svg" />
        <div className="group-parent43" onClick={onGroupContainerClick}>
          <img className="group-child159" alt="" src="/group-71.svg" />
          <img className="group-child160" alt="" src="/group-81@2x.png" />
        </div>
        <div className="ana-sayfa10">Ana Sayfa</div>
        <a className="profil10" onClick={onProfilClick}>
          <img className="group-child160" alt="" src="/group-2094@2x.png" />
        </a>
        <img className="group-child161" alt="" src="/group-182052@2x.png" />
        <img className="group-child162" alt="" src="/group-182224@2x.png" />
        <a className="profil10" onClick={onProfil1Click}>
          <img className="group-child160" alt="" src="/group-2094@2x.png" />
        </a>
      </section>
    </div>
  );
};

FooterSection1.propTypes = {
  className: PropTypes.string,
};

export default FooterSection1;
