import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Aile8.css";

const Aile8 = () => {
  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/aile110");
  }, [navigate]);

  const onGroupIcon2Click = useCallback(() => {
    navigate("/aile18");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="aile-19">
      <img className="aile-19-icon" alt="" src="/aile19@2x.png" />
      <div className="component-1-22">
        <div className="sayfa-914">Sayfa: 9/14</div>
        <img
          className="component-1-22-child"
          alt=""
          src="/group-183982@2x.png"
        />
      </div>
      <div className="aile-19-inner">
        <section className="path-18812-parent3" id="menu-bar">
          <img className="path-18812-icon6" alt="" src="/path-18812.svg" />
          <img className="path-18813-icon6" alt="" src="/path-188131.svg" />
          <div className="group-parent32" onClick={onGroupContainerClick}>
            <img className="group-child88" alt="" src="/group-71.svg" />
            <img className="group-child89" alt="" src="/group-81@2x.png" />
          </div>
          <div className="ana-sayfa6">Ana Sayfa</div>
          <a className="profil6" onClick={onProfilClick}>
            <img className="group-child89" alt="" src="/group-2092@2x.png" />
          </a>
          <img className="group-child90" alt="" src="/group-182051@2x.png" />
          <img className="group-child91" alt="" src="/group-182222@2x.png" />
        </section>
      </div>
      <img
        className="aile-19-child"
        alt=""
        src="/group-197422@2x.png"
        onClick={onGroupIcon1Click}
      />
      <img
        className="aile-19-item"
        alt=""
        src="/group-197432@2x.png"
        onClick={onGroupIcon2Click}
      />
      <div className="sonraki-sayfa20">Sonraki Sayfa</div>
      <div className="nceki-sayfa19">Önceki Sayfa</div>
      <div className="sesi-a21">Sesi Aç</div>
      <div className="szlk21">Sözlük</div>
      <img className="aile-19-child1" alt="" src="/group-19744@2x.png" />
      <img className="aile-19-child2" alt="" src="/group-197452@2x.png" />
      <div className="path-20190-parent17">
        <img className="path-20190-icon19" alt="" src="/path-20190.svg" />
        <div className="sonra-peimize-dp-container" data-animate-on-scroll>
          <p className="sonra-peimize-dp">
            Sonra peşimize düşüp bizi yakalamaya çalışıyor. Öyle eğlenceli bir
            şey ki bu! Dedem, geçen yıl bizimle yaşamaya başladı. Annemle babam
            "Yalnız yaşamak çok zor, bizimle olursan rahat edersin, biz de
            memnun oluruz!" dediler dedeme. İşte, ne kadar büyürsen büyü her gün
            yeni şeyler öğrenebiliyorsun.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aile8;
