import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/FooterSection";
import "./IpekPage6.css";

const IpekPage6 = () => {
  const navigate = useNavigate();

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIconClick = useCallback(() => {
    navigate("/ipekpage8");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/ipekpage6");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="ipek-page-7">
      <img className="ipek-page-7-child" alt="" src="/group-138146@2x.png" />
      <div className="component-1-7">
        <div className="sayfa-713">Sayfa: 7/13</div>
        <img
          className="component-1-7-child"
          alt=""
          src="/group-183981@2x.png"
        />
      </div>
      <FooterSection
        group81="/group-81@2x.png"
        group209="/group-20931@2x.png"
        group18205="/group-1820511@2x.png"
        group18222="/group-1822221@2x.png"
        path18813IconHeight="calc(100% - 31.3px)"
        onProfilClick={onProfilClick}
      />
      <img
        className="ipek-page-7-item"
        alt=""
        src="/group-1974211@2x.png"
        onClick={onGroupIconClick}
      />
      <img
        className="ipek-page-7-inner"
        alt=""
        src="/group-197431@2x.png"
        onClick={onGroupIcon1Click}
      />
      <div className="sonraki-sayfa6">Sonraki Sayfa</div>
      <div className="nceki-sayfa5">Önceki Sayfa</div>
      <div className="sesi-a6">Sesi Aç</div>
      <div className="szlk6">Sözlük</div>
      <div className="ipek-page-7-inner1">
        <div className="group-parent11">
          <img className="group-child27" alt="" src="/group-257.svg" />
          <div className="group-parent11">
            <div className="group-parent11">
              <img className="group-child28" alt="" src="/group-262.svg" />
              <img className="path-397-icon6" alt="" src="/path-397.svg" />
              <img className="group-child29" alt="" src="/group-265.svg" />
              <img className="path-399-icon6" alt="" src="/path-399.svg" />
              <img className="path-400-icon6" alt="" src="/path-400.svg" />
              <img className="path-401-icon6" alt="" src="/path-401.svg" />
              <img
                className="group-child30"
                alt=""
                src="/rectangle-141@2x.png"
              />
              <img
                className="group-child31"
                alt=""
                src="/rectangle-142@2x.png"
              />
              <img className="path-402-icon6" alt="" src="/path-402.svg" />
              <img className="path-403-icon6" alt="" src="/path-403.svg" />
              <img className="path-404-icon6" alt="" src="/path-404.svg" />
              <audio className="path-4056" autoPlay controls>
                <source src="https://mobil.okumanya.com.tr/voice/ipek/ipek-ses-7.mp3" />
              </audio>
              <img className="path-406-icon6" alt="" src="/path-406.svg" />
              <img className="path-407-icon6" alt="" src="/path-407.svg" />
            </div>
          </div>
        </div>
      </div>
      <img className="ipek-page-7-child1" alt="" src="/group-197451@2x.png" />
      <div className="path-20190-parent2">
        <img className="path-20190-icon4" alt="" src="/path-20190.svg" />
        <div className="pek-deniz-kysnda" data-animate-on-scroll>
          İpek, deniz kıyısında kumdan kaleler yapmaktan çok hoşlanır. Evet!
          Denizi çok severim. Çünkü denizin sesini dinlemek ve dalgalarla
          oynamak bana huzur verir.
        </div>
      </div>
    </div>
  );
};

export default IpekPage6;
