import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Aile12.css";

const Aile12 = () => {
  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/aile114");
  }, [navigate]);

  const onGroupIcon2Click = useCallback(() => {
    navigate("/aile112");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="aile-113">
      <img className="aile-113-icon" alt="" src="/aile113@2x.png" />
      <div className="component-1-26">
        <div className="sayfa-1314">Sayfa: 13/14</div>
        <img
          className="component-1-26-child"
          alt=""
          src="/group-183982@2x.png"
        />
      </div>
      <div className="aile-113-inner">
        <section className="path-18812-parent6" id="menu-bar">
          <img className="path-18812-icon9" alt="" src="/path-18812.svg" />
          <img className="path-18813-icon9" alt="" src="/path-188131.svg" />
          <div className="group-parent35" onClick={onGroupContainerClick}>
            <img className="group-child100" alt="" src="/group-71.svg" />
            <img className="group-child101" alt="" src="/group-811@2x.png" />
          </div>
          <div className="ana-sayfa9">Ana Sayfa</div>
          <a className="profil9" onClick={onProfilClick}>
            <img className="group-child101" alt="" src="/group-2093@2x.png" />
          </a>
          <img className="group-child102" alt="" src="/group-182051@2x.png" />
          <img className="group-child103" alt="" src="/group-182222@2x.png" />
        </section>
      </div>
      <img
        className="aile-113-child"
        alt=""
        src="/group-197423@2x.png"
        onClick={onGroupIcon1Click}
      />
      <img
        className="aile-113-item"
        alt=""
        src="/group-197434@2x.png"
        onClick={onGroupIcon2Click}
      />
      <div className="sonraki-sayfa24">Sonraki Sayfa</div>
      <div className="nceki-sayfa23">Önceki Sayfa</div>
      <div className="sesi-a25">Sesi Aç</div>
      <div className="szlk25">Sözlük</div>
      <img className="aile-113-child1" alt="" src="/group-19744@2x.png" />
      <img className="aile-113-child2" alt="" src="/group-197452@2x.png" />
      <div className="path-20190-parent21">
        <img className="path-20190-icon23" alt="" src="/path-20190.svg" />
        <div
          className="siz-de-yedi"
          data-animate-on-scroll
        >{`Siz de yedi yaşındaysanız ve yeterince büyük olduğunuzu düşünüyorsanız bunu herkese anlatabilirsiniz. Ya da bir deftere yazabilirsiniz ailenizdeki kişileri, onların özelliklerini. Sonra da okuyabilirsiniz. `}</div>
      </div>
    </div>
  );
};

export default Aile12;
