import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/FooterSection";
import "./IpekPage2.css";

const IpekPage2 = () => {
  const navigate = useNavigate();

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIconClick = useCallback(() => {
    navigate("/ipekpage4");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/ipekpage2");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="ipek-page-3">
      <img className="ipek-page-3-child" alt="" src="/group-138142@2x.png" />
      <div className="component-1-3">
        <div className="sayfa-313">Sayfa: 3/13</div>
        <img className="component-1-3-child" alt="" src="/group-18398@2x.png" />
      </div>
      <FooterSection
        group81="/group-81@2x.png"
        group209="/group-20911@2x.png"
        group18205="/group-1820511@2x.png"
        group18222="/group-1822211@2x.png"
        path18813IconHeight="calc(100% - 31.3px)"
        onProfilClick={onProfilClick}
      />
      <img
        className="ipek-page-3-item"
        alt=""
        src="/group-197425@2x.png"
        onClick={onGroupIconClick}
      />
      <img
        className="ipek-page-3-inner"
        alt=""
        src="/group-19743@2x.png"
        onClick={onGroupIcon1Click}
      />
      <div className="sonraki-sayfa2">Sonraki Sayfa</div>
      <div className="nceki-sayfa1">Önceki Sayfa</div>
      <div className="sesi-a2">Sesi Aç</div>
      <div className="szlk2">Sözlük</div>
      <div className="ipek-page-3-inner1">
        <div className="group-parent3">
          <img className="group-child7" alt="" src="/group-257.svg" />
          <div className="group-parent3">
            <div className="group-parent3">
              <img className="group-child8" alt="" src="/group-262.svg" />
              <img className="path-397-icon2" alt="" src="/path-397.svg" />
              <img className="group-child9" alt="" src="/group-265.svg" />
              <img className="path-399-icon2" alt="" src="/path-399.svg" />
              <img className="path-400-icon2" alt="" src="/path-400.svg" />
              <img className="path-401-icon2" alt="" src="/path-401.svg" />
              <img
                className="group-child10"
                alt=""
                src="/rectangle-141@2x.png"
              />
              <img
                className="group-child11"
                alt=""
                src="/rectangle-142@2x.png"
              />
              <img className="path-402-icon2" alt="" src="/path-402.svg" />
              <img className="path-403-icon2" alt="" src="/path-403.svg" />
              <img className="path-404-icon2" alt="" src="/path-404.svg" />
              <audio className="path-4052" autoPlay controls>
                <source src="https://mobil.okumanya.com.tr/voice/ipek/ipek-ses-3.mp3" />
              </audio>
              <img className="path-406-icon2" alt="" src="/path-406.svg" />
              <img className="path-407-icon2" alt="" src="/path-407.svg" />
            </div>
          </div>
        </div>
      </div>
      <img className="ipek-page-3-child1" alt="" src="/group-19745@2x.png" />
      <div className="path-20190-parent">
        <img className="path-20190-icon" alt="" src="/path-20190.svg" />
        <div className="pek-balonlara-baylr" data-animate-on-scroll>
          İpek, balonlara bayılır. Evet, balonlara bayılırım. Yani balon görünce
          bayılmam canım. Onlardan çok hoşlanırım demek istiyorum. Capcanlı
          renkleri beni çok heyecanlandırır. Her rengin benim için bir anlamı
          vardır. En sevdiğim renk mavi. Bana gökyüzünü hatırlatır ve bana uçma
          hissi verir. Uçuyor gibi hissederim.
        </div>
      </div>
    </div>
  );
};

export default IpekPage2;
