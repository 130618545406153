import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/FooterSection";
import "./Aile13.css";

const Aile13 = () => {
  const navigate = useNavigate();

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIconClick = useCallback(() => {
    navigate("/aile113");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onGroupContainer2Click = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  return (
    <div className="aile-114">
      <img className="aile-114-icon" alt="" src="/aile114@2x.png" />
      <div className="component-1-27">
        <div className="sayfa-1414">Sayfa: 14/14</div>
        <img
          className="component-1-27-child"
          alt=""
          src="/group-183983@2x.png"
        />
      </div>
      <FooterSection
        group81="/group-811@2x.png"
        group209="/group-2092@2x.png"
        group18205="/group-182051@2x.png"
        group18222="/group-182222@2x.png"
        path18813IconHeight="calc(100% - 31.2px)"
        onProfilClick={onProfilClick}
      />
      <img
        className="aile-114-child"
        alt=""
        src="/group-197433@2x.png"
        onClick={onGroupIconClick}
      />
      <div className="nceki-sayfa24">Önceki Sayfa</div>
      <div className="sesi-a26">Sesi Aç</div>
      <div className="szlk26">Sözlük</div>
      <img className="aile-114-item" alt="" src="/group-19744@2x.png" />
      <img className="aile-114-inner" alt="" src="/group-197452@2x.png" />
      <div className="path-20190-parent22">
        <img className="path-20190-icon24" alt="" src="/path-20190.svg" />
        <div
          className="o-zaman-dnn"
          data-animate-on-scroll
        >{`O zaman düşünün bakalım sevgili arkadaşlarım. Kimler var evinizde? Kaç kişiler? (Kendinizi de saymayı unutmayın sakın.) İsimleri neler, sizin neyiniz olurlar? Onların hangi özelliklerini biliyorsunuz? `}</div>
      </div>
      <div className="son1">Son</div>
      <div className="group-parent36" onClick={onGroupContainer2Click}>
        <img className="group-child104" alt="" src="/group-19748.svg" />
        <img className="group-child105" alt="" src="/group-19758@2x.png" />
      </div>
    </div>
  );
};

export default Aile13;
