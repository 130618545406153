import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/FooterSection";
import "./Aile5.css";

const Aile5 = () => {
  const navigate = useNavigate();

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIconClick = useCallback(() => {
    navigate("/aile17");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/aile15");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="aile-16">
      <img className="aile-16-icon" alt="" src="/aile16@2x.png" />
      <div className="component-1-19">
        <div className="sayfa-614">Sayfa: 6/14</div>
        <img
          className="component-1-19-child"
          alt=""
          src="/group-183981@2x.png"
        />
      </div>
      <FooterSection
        group81="/group-81@2x.png"
        group209="/group-2091@2x.png"
        group18205="/group-18205@2x.png"
        group18222="/group-182221@2x.png"
        path18813IconHeight="calc(100% - 31.2px)"
        onProfilClick={onProfilClick}
      />
      <img
        className="aile-16-child"
        alt=""
        src="/group-197421@2x.png"
        onClick={onGroupIconClick}
      />
      <img
        className="aile-16-item"
        alt=""
        src="/group-197431@2x.png"
        onClick={onGroupIcon1Click}
      />
      <div className="sonraki-sayfa17">Sonraki Sayfa</div>
      <div className="nceki-sayfa16">Önceki Sayfa</div>
      <div className="sesi-a18">Sesi Aç</div>
      <div className="szlk18">Sözlük</div>
      <img className="aile-16-inner" alt="" src="/group-19744@2x.png" />
      <img className="aile-16-child1" alt="" src="/group-197451@2x.png" />
      <div className="path-20190-parent14">
        <img className="path-20190-icon16" alt="" src="/path-20190.svg" />
        <div className="abimin-ad-tun-container" data-animate-on-scroll>
          <p className="abimin-ad-tun">
            Abimin adı Tunç. On iki yaşında, çok yaramaz. Genelde evin en küçüğü
            afacan olur. Bizde pek öyle değil.
          </p>
          <p className="dur-artk-tun">“Dur artık Tunç!”</p>
          <p className="abimin-ad-tun"> “Otur artık Tunç!”</p>
          <p className="abimin-ad-tun"> “Kafamız şişti Tunç!”</p>
        </div>
      </div>
    </div>
  );
};

export default Aile5;
