import { useEffect } from "react";
import GroupComponent1 from "../components/GroupComponent1";
import "./GirisMobil.css";

const GirisMobil = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="giris-mobil">
      <div className="giris-mobil-child" />
      <img
        className="giris-mobil-item"
        alt=""
        src="/group-13626.svg"
        data-animate-on-scroll
      />
      <GroupComponent1 />
      <img
        className="fhjb-w567-210823-converted-0-icon"
        alt=""
        src="/fhjb-w567-210823-converted03@2x.png"
        data-animate-on-scroll
      />
      <img
        className="fhjb-w567-210823-converted-0-icon1"
        alt=""
        src="/fhjb-w567-210823-converted02@2x.png"
        data-animate-on-scroll
      />
      <img className="giris-mobil-inner" alt="" src="/group-13628@2x.png" />
    </div>
  );
};

export default GirisMobil;
