import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/FooterSection";
import "./IpekPage1.css";

const IpekPage1 = () => {
  const navigate = useNavigate();

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onGroupIconClick = useCallback(() => {
    navigate("/ipekpage3");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/ipekpage1");
  }, [navigate]);

  return (
    <div className="ipek-page-2">
      <img className="ipek-page-2-child" alt="" src="/group-138141@2x.png" />
      <div className="component-1-2">
        <div className="sayfa-213">Sayfa: 2/13</div>
        <img className="component-1-2-child" alt="" src="/group-18398@2x.png" />
      </div>
      <FooterSection
        group81="/group-81@2x.png"
        group209="/group-20911@2x.png"
        group18205="/group-1820511@2x.png"
        group18222="/group-1822211@2x.png"
        path18813IconHeight="calc(100% - 31.3px)"
        onProfilClick={onProfilClick}
      />
      <div className="bu-rehberde-size-container" data-animate-on-scroll>
        <p className="bu-rehberde-size">
          Bu rehberde size, hoşlandığım ve hoşlanmadığım
        </p>
        <p className="bu-rehberde-size">{`şeylerden söz etmek istiyorum. Çünkü bunları `}</p>
        <p className="bu-rehberde-size">{`öğrenirseniz beni daha iyi tanırsınız. Beni daha iyi `}</p>
        <p className="bu-rehberde-size">{`tanırsanız, çok iyi anlaşabiliriz. `}</p>
        <p className="bu-rehberde-size">Hazırsanız başlıyorum…</p>
      </div>
      <img
        className="ipek-page-2-item"
        alt=""
        src="/group-197425@2x.png"
        onClick={onGroupIconClick}
      />
      <img
        className="ipek-page-2-inner"
        alt=""
        src="/group-19743@2x.png"
        onClick={onGroupIcon1Click}
      />
      <div className="sonraki-sayfa1">Sonraki Sayfa</div>
      <div className="nceki-sayfa">Önceki Sayfa</div>
      <div className="sesi-a1">Sesi Aç</div>
      <div className="szlk1">Sözlük</div>
      <div className="group-div">
        <div className="group-frame">
          <img className="group-child2" alt="" src="/group-257.svg" />
          <div className="group-frame">
            <div className="group-frame">
              <img className="group-child3" alt="" src="/group-262.svg" />
              <img className="path-397-icon1" alt="" src="/path-397.svg" />
              <img className="group-child4" alt="" src="/group-265.svg" />
              <img className="path-399-icon1" alt="" src="/path-399.svg" />
              <img className="path-400-icon1" alt="" src="/path-400.svg" />
              <img className="path-401-icon1" alt="" src="/path-401.svg" />
              <img
                className="group-child5"
                alt=""
                src="/rectangle-141@2x.png"
              />
              <img
                className="group-child6"
                alt=""
                src="/rectangle-142@2x.png"
              />
              <img className="path-402-icon1" alt="" src="/path-402.svg" />
              <img className="path-403-icon1" alt="" src="/path-403.svg" />
              <img className="path-404-icon1" alt="" src="/path-404.svg" />
              <audio className="path-4051" autoPlay controls>
                <source src="https://mobil.okumanya.com.tr/voice/ipek/ipek-ses-2.mp3" />
              </audio>
              <img className="path-406-icon1" alt="" src="/path-406.svg" />
              <img className="path-407-icon1" alt="" src="/path-407.svg" />
            </div>
          </div>
        </div>
      </div>
      <img className="ipek-page-2-child1" alt="" src="/group-19745@2x.png" />
    </div>
  );
};

export default IpekPage1;
