import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import GirisMobil from "./pages/GirisMobil";
import Yapayzeka from "./pages/Yapayzeka";
import IpekPage from "./pages/IpekPage";
import IpekPage1 from "./pages/IpekPage1";
import IpekPage2 from "./pages/IpekPage2";
import IpekPage3 from "./pages/IpekPage3";
import IpekPage4 from "./pages/IpekPage4";
import IpekPage5 from "./pages/IpekPage5";
import IpekPage6 from "./pages/IpekPage6";
import IpekPage7 from "./pages/IpekPage7";
import IpekPage8 from "./pages/IpekPage8";
import IpekPage11 from "./pages/IpekPage11";
import IpekPage12 from "./pages/IpekPage12";
import IpekPage13 from "./pages/IpekPage13";
import IpekPage14 from "./pages/IpekPage14";
import Aile from "./pages/Aile";
import Aile1 from "./pages/Aile1";
import Aile2 from "./pages/Aile2";
import Aile3 from "./pages/Aile3";
import Aile4 from "./pages/Aile4";
import Aile5 from "./pages/Aile5";
import Aile6 from "./pages/Aile6";
import Aile7 from "./pages/Aile7";
import Aile8 from "./pages/Aile8";
import Aile9 from "./pages/Aile9";
import Aile10 from "./pages/Aile10";
import Aile11 from "./pages/Aile11";
import Aile12 from "./pages/Aile12";
import Aile13 from "./pages/Aile13";
import AnaPanelMobil from "./pages/AnaPanelMobil";
import KayitFormuMobil from "./pages/KayitFormuMobil";
import ProfilMobil from "./pages/ProfilMobil";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/yapayzeka":
        title = "";
        metaDescription = "";
        break;
      case "/ipekpage1":
        title = "";
        metaDescription = "";
        break;
      case "/ipekpage2":
        title = "";
        metaDescription = "";
        break;
      case "/ipekpage3":
        title = "";
        metaDescription = "";
        break;
      case "/ipekpage4":
        title = "";
        metaDescription = "";
        break;
      case "/ipekpage5":
        title = "";
        metaDescription = "";
        break;
      case "/ipekpage6":
        title = "";
        metaDescription = "";
        break;
      case "/ipekpage7":
        title = "";
        metaDescription = "";
        break;
      case "/ipekpage8":
        title = "";
        metaDescription = "";
        break;
      case "/ipekpage9":
        title = "";
        metaDescription = "";
        break;
      case "/ipekpage10":
        title = "";
        metaDescription = "";
        break;
      case "/ipekpage11":
        title = "";
        metaDescription = "";
        break;
      case "/ipekpage12":
        title = "";
        metaDescription = "";
        break;
      case "/ipekpage13":
        title = "";
        metaDescription = "";
        break;
      case "/aile11":
        title = "";
        metaDescription = "";
        break;
      case "/aile12":
        title = "";
        metaDescription = "";
        break;
      case "/aile13":
        title = "";
        metaDescription = "";
        break;
      case "/aile14":
        title = "";
        metaDescription = "";
        break;
      case "/aile15":
        title = "";
        metaDescription = "";
        break;
      case "/aile16":
        title = "";
        metaDescription = "";
        break;
      case "/aile17":
        title = "";
        metaDescription = "";
        break;
      case "/aile18":
        title = "";
        metaDescription = "";
        break;
      case "/aile19":
        title = "";
        metaDescription = "";
        break;
      case "/aile110":
        title = "";
        metaDescription = "";
        break;
      case "/aile111":
        title = "";
        metaDescription = "";
        break;
      case "/aile112":
        title = "";
        metaDescription = "";
        break;
      case "/aile113":
        title = "";
        metaDescription = "";
        break;
      case "/aile114":
        title = "";
        metaDescription = "";
        break;
      case "/ana-panel-mobil":
        title = "";
        metaDescription = "";
        break;
      case "/kayitformumobil":
        title = "";
        metaDescription = "";
        break;
      case "/profil-mobil":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<GirisMobil />} />
      <Route path="/yapayzeka" element={<Yapayzeka />} />
      <Route path="/ipekpage1" element={<IpekPage />} />
      <Route path="/ipekpage2" element={<IpekPage1 />} />
      <Route path="/ipekpage3" element={<IpekPage2 />} />
      <Route path="/ipekpage4" element={<IpekPage3 />} />
      <Route path="/ipekpage5" element={<IpekPage4 />} />
      <Route path="/ipekpage6" element={<IpekPage5 />} />
      <Route path="/ipekpage7" element={<IpekPage6 />} />
      <Route path="/ipekpage8" element={<IpekPage7 />} />
      <Route path="/ipekpage9" element={<IpekPage8 />} />
      <Route path="/ipekpage10" element={<IpekPage11 />} />
      <Route path="/ipekpage11" element={<IpekPage12 />} />
      <Route path="/ipekpage12" element={<IpekPage13 />} />
      <Route path="/ipekpage13" element={<IpekPage14 />} />
      <Route path="/aile11" element={<Aile />} />
      <Route path="/aile12" element={<Aile1 />} />
      <Route path="/aile13" element={<Aile2 />} />
      <Route path="/aile14" element={<Aile3 />} />
      <Route path="/aile15" element={<Aile4 />} />
      <Route path="/aile16" element={<Aile5 />} />
      <Route path="/aile17" element={<Aile6 />} />
      <Route path="/aile18" element={<Aile7 />} />
      <Route path="/aile19" element={<Aile8 />} />
      <Route path="/aile110" element={<Aile9 />} />
      <Route path="/aile111" element={<Aile10 />} />
      <Route path="/aile112" element={<Aile11 />} />
      <Route path="/aile113" element={<Aile12 />} />
      <Route path="/aile114" element={<Aile13 />} />
      <Route path="/ana-panel-mobil" element={<AnaPanelMobil />} />
      <Route path="/kayitformumobil" element={<KayitFormuMobil />} />
      <Route path="/profil-mobil" element={<ProfilMobil />} />
    </Routes>
  );
}
export default App;
