import { useMemo } from "react";
import "./BottomFooter.css";

const BottomFooter = ({
  className = "",
  propLeft,
  onGroupContainerClick,
  onProfilClick,
}) => {
  const groupFooterStyle = useMemo(() => {
    return {
      left: propLeft,
    };
  }, [propLeft]);

  return (
    <footer
      className={`ana-panel-mobil-inner2 ${className}`}
      id="menu-bar"
      style={groupFooterStyle}
    >
      <section className="path-18813-parent" id="menu-bar">
        <img className="path-18813-icon13" alt="" src="/path-18813.svg" />
        <div className="path-18812-parent10">
          <img className="path-18812-icon13" alt="" src="/path-18812.svg" />
          <div className="group-parent47" onClick={onGroupContainerClick}>
            <img className="group-child175" alt="" src="/group-71.svg" />
            <img className="group-child176" alt="" src="/group-81@2x.png" />
          </div>
          <div className="ana-sayfa13">Ana Sayfa</div>
        </div>
        <a className="profil14" onClick={onProfilClick}>
          <img className="group-child176" alt="" src="/group-2094@2x.png" />
        </a>
        <img className="group-child177" alt="" src="/group-182052@2x.png" />
        <img className="group-child178" alt="" src="/group-182224@2x.png" />
      </section>
    </footer>
  );
};

BottomFooter.propTypes = {
  className: PropTypes.string,

  /** Style props */
  propLeft: PropTypes.any,

  /** Action props */
  onGroupContainerClick: PropTypes.func,
  onProfilClick: PropTypes.func,
};

export default BottomFooter;
