import FooterSection1 from "../components/FooterSection1";
import "./Yapayzeka.css";

const Yapayzeka = () => {
  return (
    <div className="yapayzeka">
      <FooterSection1 />
    </div>
  );
};

export default Yapayzeka;
