import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/FooterSection";
import "./IpekPage3.css";

const IpekPage3 = () => {
  const navigate = useNavigate();

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIconClick = useCallback(() => {
    navigate("/ipekpage5");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/ipekpage3");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="ipek-page-4">
      <img className="ipek-page-4-child" alt="" src="/group-138143@2x.png" />
      <div className="component-1-4">
        <div className="sayfa-413">Sayfa: 4/13</div>
        <img className="component-1-4-child" alt="" src="/group-18398@2x.png" />
      </div>
      <FooterSection
        group81="/group-81@2x.png"
        group209="/group-20911@2x.png"
        group18205="/group-1820511@2x.png"
        group18222="/group-1822221@2x.png"
        path18813IconHeight="calc(100% - 31.3px)"
        onProfilClick={onProfilClick}
      />
      <img
        className="ipek-page-4-item"
        alt=""
        src="/group-1974211@2x.png"
        onClick={onGroupIconClick}
      />
      <img
        className="ipek-page-4-inner"
        alt=""
        src="/group-19743@2x.png"
        onClick={onGroupIcon1Click}
      />
      <div className="sonraki-sayfa3">Sonraki Sayfa</div>
      <div className="nceki-sayfa2">Önceki Sayfa</div>
      <div className="sesi-a3">Sesi Aç</div>
      <div className="szlk3">Sözlük</div>
      <div className="ipek-page-4-inner1">
        <div className="group-parent5">
          <img className="group-child12" alt="" src="/group-257.svg" />
          <div className="group-parent5">
            <div className="group-parent5">
              <img className="group-child13" alt="" src="/group-262.svg" />
              <img className="path-397-icon3" alt="" src="/path-397.svg" />
              <img className="group-child14" alt="" src="/group-265.svg" />
              <img className="path-399-icon3" alt="" src="/path-399.svg" />
              <img className="path-400-icon3" alt="" src="/path-400.svg" />
              <img className="path-401-icon3" alt="" src="/path-401.svg" />
              <img
                className="group-child15"
                alt=""
                src="/rectangle-141@2x.png"
              />
              <img
                className="group-child16"
                alt=""
                src="/rectangle-142@2x.png"
              />
              <img className="path-402-icon3" alt="" src="/path-402.svg" />
              <img className="path-403-icon3" alt="" src="/path-403.svg" />
              <img className="path-404-icon3" alt="" src="/path-404.svg" />
              <audio className="path-4053" autoPlay controls>
                <source src="https://mobil.okumanya.com.tr/voice/ipek/ipek-ses-4.mp3" />
              </audio>
              <img className="path-406-icon3" alt="" src="/path-406.svg" />
              <img className="path-407-icon3" alt="" src="/path-407.svg" />
            </div>
          </div>
        </div>
      </div>
      <img className="ipek-page-4-child1" alt="" src="/group-197451@2x.png" />
      <div className="path-20190-group">
        <img className="path-20190-icon1" alt="" src="/path-20190.svg" />
        <div className="ama-pek-sebzeleri" data-animate-on-scroll>
          Ama İpek, sebzeleri hiç sevmez. Biri ne zaman brokoli, havuç, ıspanak
          dese İpek, hepsinden kaçar! “Hayırrrrrr! Teşekkürler!” der, kendisini
          odasına kapatır. Anlayacağınız ben, sebzelerden hiç hoşlanmam.
        </div>
      </div>
    </div>
  );
};

export default IpekPage3;
