import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ScrollGroup from "../components/ScrollGroup";
import BottomFooter from "../components/BottomFooter";
import "./ProfilMobil.css";

const ProfilMobil = () => {
  const navigate = useNavigate();

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupContainerClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onImage11Click = useCallback(() => {
    navigate("/aile11");
  }, [navigate]);

  const onImage48Click = useCallback(() => {
    navigate("/ipekpage1");
  }, [navigate]);

  const onImage121Click = useCallback(() => {
    navigate("/ipekpage1");
  }, [navigate]);

  const onImage131Click = useCallback(() => {
    navigate("/aile11");
  }, [navigate]);

  const onImage491Click = useCallback(() => {
    navigate("/aile11");
  }, [navigate]);

  const onImage501Click = useCallback(() => {
    navigate("/ipekpage1");
  }, [navigate]);

  return (
    <div className="profil-mobil">
      <div className="profil-mobil-child" />
      <div className="mehmet-ali-srmeli-group" data-animate-on-scroll>
        <div className="mehmet-ali-srmeli1">Mehmet Ali Sürmeli</div>
        <div className="snf1">3.Sınıf</div>
        <div className="group-child122" />
        <img className="group-child123" alt="" src="/group-34.svg" />
      </div>
      <div className="proflm" data-animate-on-scroll>
        PROFİLİM
      </div>
      <div className="profil-mobil-item" data-animate-on-scroll />
      <div className="statistikler-wrapper" data-animate-on-scroll>
        <div className="statistikler">İstatistikler</div>
      </div>
      <div className="rozetlerim-wrapper" data-animate-on-scroll>
        <div className="rozetlerim">Rozetlerim</div>
      </div>
      <div className="puan-ve-yorumlarm">Puan ve Yorumlarım</div>
      <div className="kitaplm-wrapper" data-animate-on-scroll>
        <div className="kitaplm">Kitaplığım</div>
      </div>
      <div className="arkadalar-wrapper" data-animate-on-scroll>
        <div className="arkadalar">Arkadaşlar</div>
      </div>
      <h3 className="toplam-okuma-sresi">Toplam Okuma Süresi</h3>
      <div className="ortalama-okuma-hz">Ortalama Okuma Hızı</div>
      <div className="en-ok-okuduun">En Çok Okuduğun Türler</div>
      <h4 className="saat-21-dakika">325 Saat, 21 Dakika, 15 Saniye</h4>
      <div className="dakikada-80-kelime">1 Dakikada 80 Kelime</div>
      <div className="macera">Macera</div>
      <div className="yk">Öykü</div>
      <div className="bilim-kurgu">Bilim Kurgu</div>
      <div className="masal">Masal</div>
      <div className="gezi">Gezi</div>
      <div className="kitap1">20 Kitap</div>
      <div className="kitap2">15 Kitap</div>
      <div className="kitap3">12 Kitap</div>
      <div className="kitap4">10 Kitap</div>
      <div className="kitap5">8 Kitap</div>
      <img className="profil-mobil-inner" alt="" src="/group-13856.svg" />
      <div className="profil-mobil-child1" />
      <div className="profil-mobil-child2" />
      <div className="profil-mobil-child3" />
      <div className="profil-mobil-child4" />
      <div className="profil-mobil-child5" />
      <div className="profil-mobil-child6" />
      <div className="profil-mobil-child7" />
      <div className="profil-mobil-child8" />
      <img className="profil-mobil-child9" alt="" src="/group-13859.svg" />
      <img className="profil-mobil-child10" alt="" src="/group-13862.svg" />
      <img className="profil-mobil-child11" alt="" src="/group-13865.svg" />
      <img className="profil-mobil-child12" alt="" src="/group-13867.svg" />
      <img className="profil-mobil-child13" alt="" src="/group-13870.svg" />
      <img className="profil-mobil-child14" alt="" src="/group-13873.svg" />
      <img className="profil-mobil-child15" alt="" src="/group-13876.svg" />
      <div className="group-parent37">
        <div className="en-hzl-kitap-okuma-parent" data-animate-on-scroll>
          <div className="en-hzl-kitap-okuma-container">
            <p className="en-hzl">En Hızlı</p>
            <p className="en-hzl">Kitap Okuma</p>
          </div>
          <img className="group-child124" alt="" src="/group-13966.svg" />
        </div>
        <div className="en-ok-kitap-okuma-parent" data-animate-on-scroll>
          <div className="en-ok-kitap-okuma-container">
            <p className="en-hzl">En Çok</p>
            <p className="en-hzl">Kitap Okuma</p>
          </div>
          <img className="group-child125" alt="" src="/group-13965.svg" />
        </div>
        <div
          className="eitli-trlerde-kitap-okuma-parent"
          data-animate-on-scroll
        >
          <div className="en-ok-kitap-okuma-container">
            <p className="en-hzl">Çeşitli Türlerde</p>
            <p className="en-hzl">Kitap Okuma</p>
          </div>
          <img className="group-child126" alt="" src="/group-13967.svg" />
        </div>
      </div>
      <div className="profil-mobil-child16" />
      <div className="profil-mobil-child17" />
      <div className="profil-mobil-child18" />
      <div className="profil-mobil-child19" />
      <div className="profil-mobil-child20" />
      <div className="profil-mobil-child21" />
      <div className="profil-mobil-child22" />
      <div className="okumanya-siralaman">OKUMANYA SIRALAMAN</div>
      <div className="mehmet-kaya">Mehmet Kaya</div>
      <div className="nefise-tahsinolu">Nefise Tahsinoğlu</div>
      <div className="makbule-trkolu">Makbule Türkoğlu</div>
      <div className="mehmet-ali-srmeli2">Mehmet Ali Sürmeli</div>
      <div className="profil-mobil-inner1">
        <div className="group-parent38">
          <img className="group-child127" alt="" src="/group-13926.svg" />
          <div className="div9">1</div>
        </div>
      </div>
      <div className="profil-mobil-inner2">
        <div className="group-parent38">
          <img className="group-child127" alt="" src="/group-13939.svg" />
          <div className="div10">2</div>
        </div>
      </div>
      <div className="profil-mobil-inner3">
        <div className="group-parent38">
          <img className="group-child127" alt="" src="/group-13946.svg" />
          <div className="div11">3</div>
        </div>
      </div>
      <div className="profil-mobil-inner4">
        <div className="group-parent38">
          <img className="group-child127" alt="" src="/group-13953.svg" />
          <div className="div12">45</div>
        </div>
      </div>
      <div className="profil-mobil-child23" />
      <div className="puan-parent">
        <div className="puan">
          <p className="en-hzl">3600</p>
          <p className="en-hzl">Puan</p>
        </div>
        <img className="group-child131" alt="" src="/group-13956@2x.png" />
      </div>
      <div className="puan2">
        <p className="en-hzl">2800</p>
        <p className="en-hzl">Puan</p>
      </div>
      <div className="puan4">
        <p className="en-hzl">2300</p>
        <p className="en-hzl">Puan</p>
      </div>
      <div className="puan6">
        <p className="en-hzl">1230</p>
        <p className="en-hzl">Puan</p>
      </div>
      <img className="profil-mobil-child24" alt="" src="/group-139561@2x.png" />
      <img className="profil-mobil-child25" alt="" src="/group-139561@2x.png" />
      <img className="profil-mobil-child26" alt="" src="/group-139561@2x.png" />
      <ScrollGroup />
      <div className="servet-karalar-parent">
        <div className="servet-karalar">Servet Karalar</div>
        <div className="group-wrapper12">
          <img className="group-child132" alt="" src="/group-341.svg" />
        </div>
      </div>
      <div className="mehmet-yaln">Mehmet Yalçın</div>
      <div className="profil-mobil-inner5">
        <img className="group-child133" alt="" src="/group-18174.svg" />
      </div>
      <div className="ali-srmeli">Ali Sürmeli</div>
      <div className="profil-mobil-inner6">
        <img className="group-child134" alt="" src="/group-18176.svg" />
      </div>
      <div className="hakk-etin">Hakkı Çetin</div>
      <div className="profil-mobil-inner7">
        <img className="group-child132" alt="" src="/group-341.svg" />
      </div>
      <div className="zhan-kaygsz">Özhan Kaygısız</div>
      <div className="profil-mobil-inner8">
        <img className="group-child132" alt="" src="/group-341.svg" />
      </div>
      <div className="hamdi-baemez">Hamdi Başeğmez</div>
      <div className="profil-mobil-inner9">
        <img className="group-child132" alt="" src="/group-341.svg" />
      </div>
      <div className="canan-orhan">Canan Orhan</div>
      <div className="profil-mobil-inner10">
        <img className="group-child138" alt="" src="/group-18169.svg" />
      </div>
      <div className="ezginur-tan">Ezginur Tanış</div>
      <div className="profil-mobil-inner11">
        <img className="group-child139" alt="" src="/group-18171.svg" />
      </div>
      <div className="merve-mercan">Merve Mercan</div>
      <div className="profil-mobil-inner12">
        <img className="group-child140" alt="" src="/group-18173.svg" />
      </div>
      <img className="profil-mobil-child27" alt="" src="/group-18184.svg" />
      <div className="group-parent42">
        <img className="group-child141" alt="" src="/group-18151@2x.png" />
        <img
          className="image-12-icon"
          alt=""
          src="/image-12@2x.png"
          data-animate-on-scroll
        />
        <img
          className="image-13-icon"
          alt=""
          src="/image-13@2x.png"
          data-animate-on-scroll
        />
        <img
          className="image-11-icon"
          alt=""
          src="/image-11@2x.png"
          onClick={onImage11Click}
          data-animate-on-scroll
        />
        <img className="group-child142" alt="" src="/group-16838@2x.png" />
        <img
          className="image-49-icon"
          alt=""
          src="/image-49@2x.png"
          data-animate-on-scroll
        />
        <img className="image-50-icon" alt="" src="/image-502@2x.png" />
        <img
          className="image-48-icon"
          alt=""
          src="/image-481@2x.png"
          onClick={onImage48Click}
          data-animate-on-scroll
        />
        <img
          className="image-12-icon1"
          alt=""
          src="/image-121@2x.png"
          onClick={onImage121Click}
          data-animate-on-scroll
        />
        <img
          className="image-13-icon1"
          alt=""
          src="/image-131@2x.png"
          onClick={onImage131Click}
          data-animate-on-scroll
        />
        <img
          className="image-11-icon1"
          alt=""
          src="/image-111@2x.png"
          data-animate-on-scroll
        />
        <img className="group-child143" alt="" src="/group-15665@2x.png" />
        <img
          className="image-49-icon1"
          alt=""
          src="/image-491@2x.png"
          onClick={onImage491Click}
          data-animate-on-scroll
        />
        <img
          className="image-50-icon1"
          alt=""
          src="/image-503@2x.png"
          onClick={onImage501Click}
          data-animate-on-scroll
        />
        <img
          className="image-48-icon1"
          alt=""
          src="/image-482@2x.png"
          data-animate-on-scroll
        />
        <img className="group-child144" alt="" src="/group-14712@2x.png" />
      </div>
      <img className="profil-mobil-child28" alt="" src="/group-18186@2x.png" />
      <img className="profil-mobil-child29" alt="" src="/group-18186@2x.png" />
      <img
        className="profil-mobil-child30"
        alt=""
        src="/group-18512.svg"
        data-animate-on-scroll
      />
      <BottomFooter propLeft="0px" onProfilClick={onProfilClick} />
      <BottomFooter
        propLeft="0px"
        onGroupContainerClick={onGroupContainerClick}
      />
    </div>
  );
};

export default ProfilMobil;
