import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Aile3.css";

const Aile3 = () => {
  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/aile15");
  }, [navigate]);

  const onGroupIcon2Click = useCallback(() => {
    navigate("/aile13");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="aile-14">
      <img className="aile-14-icon" alt="" src="/aile14@2x.png" />
      <div className="component-1-17">
        <div className="sayfa-414">Sayfa: 4/14</div>
        <img
          className="component-1-17-child"
          alt=""
          src="/group-18398@2x.png"
        />
      </div>
      <div className="aile-14-inner">
        <section className="path-18812-container" id="menu-bar">
          <img className="path-18812-icon2" alt="" src="/path-18812.svg" />
          <img className="path-18813-icon2" alt="" src="/path-188131.svg" />
          <div className="group-parent28" onClick={onGroupContainerClick}>
            <img className="group-child72" alt="" src="/group-71.svg" />
            <img className="profil-inner" alt="" src="/group-81@2x.png" />
          </div>
          <div className="ana-sayfa2">Ana Sayfa</div>
          <a className="profil2" onClick={onProfilClick}>
            <img className="profil-inner" alt="" src="/group-209@2x.png" />
          </a>
          <img className="group-child74" alt="" src="/group-18205@2x.png" />
          <img className="group-child75" alt="" src="/group-182221@2x.png" />
        </section>
      </div>
      <img
        className="aile-14-child"
        alt=""
        src="/group-197421@2x.png"
        onClick={onGroupIcon1Click}
      />
      <img
        className="aile-14-item"
        alt=""
        src="/group-19743@2x.png"
        onClick={onGroupIcon2Click}
      />
      <div className="sonraki-sayfa15">Sonraki Sayfa</div>
      <div className="nceki-sayfa14">Önceki Sayfa</div>
      <div className="sesi-a16">Sesi Aç</div>
      <div className="szlk16">Sözlük</div>
      <img className="aile-14-child1" alt="" src="/group-19744@2x.png" />
      <img className="aile-14-child2" alt="" src="/group-197451@2x.png" />
      <div className="path-20190-parent12">
        <img className="path-20190-icon14" alt="" src="/path-20190.svg" />
        <div className="annemin-ad-nee" data-animate-on-scroll>
          Annemin adı Neşe, bir hastanede hemşire… Her gün eve yorgun argın
          gelse de yüzünden hiç eksik olmaz gülümseme! Babamın adı Burhan, büyük
          bir restoranda aşçı… Her gün işte yemek yaptığı için evde mutfağa
          girmekte biraz nazlı!
        </div>
      </div>
    </div>
  );
};

export default Aile3;
