import "./KayitFormuMobil.css";

const KayitFormuMobil = () => {
  return (
    <div className="kayit-formu-mobil">
      <div className="kayit-formu-mobil-child" />
      <img className="kayit-formu-mobil-item" alt="" src="/group-13626.svg" />
      <div className="kayit-formu">KAYIT FORMU</div>
      <div className="kaydol-wrapper">
        <div className="kaydol">KAYDOL</div>
      </div>
      <div className="ad-parent">
        <div className="ad">Ad</div>
        <div className="ifre-belirle">Şifre Belirle</div>
        <div className="e-posta-adresi">E-Posta Adresi</div>
        <div className="doum-tarihi">Doğum Tarihi</div>
        <div className="cinsiyet">Cinsiyet</div>
        <div className="soyad">Soyad</div>
        <div className="ifreni-tekrar-yaz">Şifreni Tekrar Yaz</div>
        <div className="telefon-numaras">Telefon Numarası</div>
        <div className="group-child112" />
        <div className="group-child113" />
        <div className="group-child114" />
        <div className="group-child115" />
        <div className="group-child116" />
        <div className="group-child117" />
        <div className="group-child118" />
        <div className="group-child119" />
        <div className="group-child120" />
        <div className="group-child121" />
        <img className="path-14199-icon" alt="" src="/path-14199.svg" />
        <img className="path-14198-icon" alt="" src="/path-14199.svg" />
        <img className="path-14196-icon" alt="" src="/path-14199.svg" />
        <img className="path-14197-icon" alt="" src="/path-14199.svg" />
      </div>
    </div>
  );
};

export default KayitFormuMobil;
