import { useCallback, useEffect } from "react";
import "antd/dist/antd.min.css";
import { Collapse } from "antd";
import { useNavigate } from "react-router-dom";
import GroupComponent from "../components/GroupComponent";
import Footer from "../components/Footer";
import BottomFooter from "../components/BottomFooter";
import "./AnaPanelMobil.css";

const AnaPanelMobil = () => {
  const navigate = useNavigate();

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onGroupImage1Click = useCallback(() => {
    navigate("/aile11");
  }, [navigate]);

  const onGroupImage2Click = useCallback(() => {
    navigate("/ipekpage1");
  }, [navigate]);

  const onGroupImage3Click = useCallback(() => {
    navigate("/aile11");
  }, [navigate]);

  const onGroupImage4Click = useCallback(() => {
    navigate("/ipekpage1");
  }, [navigate]);

  const onGroupImage5Click = useCallback(() => {
    navigate("/aile11");
  }, [navigate]);

  const onGroupImage6Click = useCallback(() => {
    navigate("/aile11");
  }, [navigate]);

  const onGroupImage7Click = useCallback(() => {
    navigate("/ipekpage1");
  }, [navigate]);

  const onGroupImage8Click = useCallback(() => {
    navigate("/aile11");
  }, [navigate]);

  const onGroupImage9Click = useCallback(() => {
    navigate("/aile11");
  }, [navigate]);

  const onGroupImage10Click = useCallback(() => {
    navigate("/ipekpage1");
  }, [navigate]);

  const onGroupImage11Click = useCallback(() => {
    navigate("/aile11");
  }, [navigate]);

  const onGroupImage12Click = useCallback(() => {
    navigate("/aile11");
  }, [navigate]);

  const onGroupImage13Click = useCallback(() => {
    navigate("/ipekpage1");
  }, [navigate]);

  const onGroupImage14Click = useCallback(() => {
    navigate("/aile11");
  }, [navigate]);

  return (
    <div className="ana-panel-mobil">
      <div className="hedeflerim-wrapper" data-animate-on-scroll>
        <div className="hedeflerim">Hedeflerim</div>
      </div>
      <div className="son-okunan-kitap-wrapper" data-animate-on-scroll>
        <div className="son-okunan-kitap">Son Okunan Kitap</div>
      </div>
      <div className="favori-kitaplarm-wrapper" data-animate-on-scroll>
        <div className="favori-kitaplarm">Favori Kitaplarım</div>
      </div>
      <div className="etkinlik-ve-duyurular-wrapper" data-animate-on-scroll>
        <div className="etkinlik-ve-duyurular">Etkinlik ve Duyurular</div>
      </div>
      <div className="en-beenilenler-wrapper" data-animate-on-scroll>
        <div className="en-beenilenler">En Beğenilenler</div>
      </div>
      <div className="sorumluluklarm-wrapper" data-animate-on-scroll>
        <div className="sorumluluklarm">Sorumluluklarım</div>
      </div>
      <div className="yeni-eklenenler-wrapper" data-animate-on-scroll>
        <div className="en-beenilenler">Yeni Eklenenler</div>
      </div>
      <div className="ok-okunanlar-wrapper" data-animate-on-scroll>
        <div className="ok-okunanlar">Çok Okunanlar</div>
      </div>
      <div className="kaybolan-uak-parent" data-animate-on-scroll>
        <div className="kaybolan-uak">
          <p className="kaybolan">Kaybolan</p>
          <p className="kaybolan">Uçak</p>
        </div>
        <div className="sayfa">12 Sayfa</div>
        <div className="seviye">1.Seviye</div>
        <div className="okundu">%35 okundu</div>
      </div>
      <div className="okumaya-devam-et-wrapper" data-animate-on-scroll>
        <div className="okumaya-devam-et">Okumaya Devam Et</div>
      </div>
      <img
        className="ana-panel-mobil-child"
        alt=""
        src="/group-19718@2x.png"
        data-animate-on-scroll
      />
      <img className="ana-panel-mobil-item" alt="" src="/group-13641.svg" />
      <h2 className="profl-zet" data-animate-on-scroll>
        PROFİL ÖZETİ
      </h2>
      <GroupComponent />
      <div className="gnlk-parent">
        <div className="gnlk">Günlük</div>
        <div className="div">80</div>
        <div className="div1">7</div>
        <div className="div2">1</div>
        <div className="div3">630</div>
        <div className="div4">35</div>
        <div className="div5">7</div>
        <div className="div6">3500</div>
        <div className="div7">758</div>
        <div className="div8">365</div>
        <div className="kelime-says">
          <p className="kaybolan">Kelime</p>
          <p className="kaybolan">Sayısı</p>
        </div>
        <div className="sayfa-says">
          <p className="kaybolan">Sayfa</p>
          <p className="kaybolan">Sayısı</p>
        </div>
        <div className="kitap-says">
          <p className="kaybolan">Kitap</p>
          <p className="kaybolan">Sayısı</p>
        </div>
        <div className="haftalk">Haftalık</div>
        <div className="aylk">Aylık</div>
        <div className="line-div" />
        <div className="group-child106" />
        <div className="group-child107" />
        <div className="group-child108" />
      </div>
      <div className="ana-panel-mobil-inner" />
      <div className="rectangle-div" />
      <div className="ana-panel-mobil-child1" />
      <div className="ana-panel-mobil-child2" />
      <div className="ana-panel-mobil-child3" />
      <div className="ekim-cumhuriyet-bayram-kitap-container">
        <p className="kaybolan">29 Ekim Cumhuriyet Bayramı</p>
        <p className="kaybolan">Kitap Okuma Etkinliği</p>
      </div>
      <div className="ekim-cumhuriyet-bayram-kitap-container1">
        <p className="kaybolan">29 Ekim Cumhuriyet Bayramı</p>
        <p className="kaybolan">Kitap Okuma Etkinliği</p>
      </div>
      <div className="ekim-cumhuriyet-bayram-kitap-container2">
        <p className="kaybolan">29 Ekim Cumhuriyet Bayramı</p>
        <p className="kaybolan">Kitap Okuma Etkinliği</p>
      </div>
      <div className="ekim-cumhuriyet-bayram-kitap-container3">
        <p className="kaybolan">29 Ekim Cumhuriyet Bayramı</p>
        <p className="kaybolan">Kitap Okuma Etkinliği</p>
      </div>
      <div className="ana-panel-mobil-inner1">
        <img className="group-child109" alt="" />
      </div>
      <img
        className="ana-panel-mobil-child4"
        alt=""
        src="/group-13687@2x.png"
      />
      <img
        className="ana-panel-mobil-child5"
        alt=""
        src="/group-13687@2x.png"
      />
      <img
        className="ana-panel-mobil-child6"
        alt=""
        src="/group-13687@2x.png"
      />
      <div className="lorem-ipsum-dolor">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. In auctor mi ut risus maximus volutpat. Aenean dapibus molestie diam id feugiat. Praesent cursus ex mi, vitae porttitor nulla venenatis nec. Curabitur eu nisi at dui hendrerit mattis. `}</div>
      <div className="scroll-group-9">
        <img
          className="scroll-group-9-child"
          alt=""
          src="/group-18894@2x.png"
          onClick={onGroupImage1Click}
        />
        <img
          className="scroll-group-9-item"
          alt=""
          src="/group-18893@2x.png"
          onClick={onGroupImage2Click}
        />
        <img
          className="scroll-group-9-inner"
          alt=""
          src="/group-18895@2x.png"
        />
        <img
          className="scroll-group-9-child1"
          alt=""
          src="/group-18897@2x.png"
        />
        <img
          className="scroll-group-9-child2"
          alt=""
          src="/group-18894@2x.png"
          onClick={onGroupImage3Click}
        />
        <img
          className="scroll-group-9-child3"
          alt=""
          src="/group-18896@2x.png"
        />
      </div>
      <div className="scroll-group-1">
        <img
          className="scroll-group-1-child"
          alt=""
          src="/group-18890@2x.png"
          onClick={onGroupImage4Click}
        />
        <img
          className="scroll-group-1-item"
          alt=""
          src="/group-18891@2x.png"
          onClick={onGroupImage5Click}
        />
        <img
          className="scroll-group-1-inner"
          alt=""
          src="/group-18892@2x.png"
        />
      </div>
      <div className="mehmet-ali-srmeli-parent" data-animate-on-scroll>
        <h3 className="mehmet-ali-srmeli">Mehmet Ali Sürmeli</h3>
        <h3 className="snf">3.Sınıf</h3>
        <div className="group-child110" />
        <img className="group-child111" alt="" src="/group-34.svg" />
      </div>
      <h3 className="ho-geldin" data-animate-on-scroll>
        Hoş Geldin
      </h3>
      <img
        className="ana-panel-mobil-child7"
        alt=""
        src="/group-18512.svg"
        data-animate-on-scroll
      />
      <Footer />
      <div className="scroll-group-10">
        <img
          className="scroll-group-9-child"
          alt=""
          src="/group-18894@2x.png"
          onClick={onGroupImage6Click}
        />
        <img
          className="scroll-group-9-item"
          alt=""
          src="/group-18893@2x.png"
          onClick={onGroupImage7Click}
        />
        <img
          className="scroll-group-9-inner"
          alt=""
          src="/group-18895@2x.png"
        />
        <img
          className="scroll-group-9-child1"
          alt=""
          src="/group-18897@2x.png"
        />
        <img
          className="scroll-group-9-child2"
          alt=""
          src="/group-18894@2x.png"
          onClick={onGroupImage8Click}
        />
        <img
          className="scroll-group-9-child3"
          alt=""
          src="/group-18896@2x.png"
        />
      </div>
      <div className="scroll-group-11">
        <img
          className="scroll-group-9-child"
          alt=""
          src="/group-18894@2x.png"
          onClick={onGroupImage9Click}
        />
        <img
          className="scroll-group-9-item"
          alt=""
          src="/group-18893@2x.png"
          onClick={onGroupImage10Click}
        />
        <img
          className="scroll-group-9-inner"
          alt=""
          src="/group-18895@2x.png"
        />
        <img
          className="scroll-group-9-child1"
          alt=""
          src="/group-18897@2x.png"
        />
        <img
          className="scroll-group-9-child2"
          alt=""
          src="/group-18894@2x.png"
          onClick={onGroupImage11Click}
        />
        <img
          className="scroll-group-9-child3"
          alt=""
          src="/group-18896@2x.png"
        />
      </div>
      <div className="scroll-group-12">
        <img
          className="scroll-group-9-child"
          alt=""
          src="/group-18894@2x.png"
          onClick={onGroupImage12Click}
        />
        <img
          className="scroll-group-9-item"
          alt=""
          src="/group-18893@2x.png"
          onClick={onGroupImage13Click}
        />
        <img
          className="scroll-group-9-inner"
          alt=""
          src="/group-18895@2x.png"
        />
        <img
          className="scroll-group-9-child1"
          alt=""
          src="/group-18897@2x.png"
        />
        <img
          className="scroll-group-9-child2"
          alt=""
          src="/group-18894@2x.png"
          onClick={onGroupImage14Click}
        />
        <img
          className="scroll-group-9-child3"
          alt=""
          src="/group-18896@2x.png"
        />
      </div>
      <BottomFooter onProfilClick={onProfilClick} />
    </div>
  );
};

export default AnaPanelMobil;
