import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/FooterSection";
import "./IpekPage5.css";

const IpekPage5 = () => {
  const navigate = useNavigate();

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIconClick = useCallback(() => {
    navigate("/ipekpage7");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/ipekpage5");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="ipek-page-6">
      <img className="ipek-page-6-child" alt="" src="/group-138145@2x.png" />
      <div className="component-1-6">
        <div className="sayfa-613">Sayfa: 6/13</div>
        <img
          className="component-1-6-child"
          alt=""
          src="/group-183981@2x.png"
        />
      </div>
      <FooterSection
        group81="/group-81@2x.png"
        group209="/group-20931@2x.png"
        group18205="/group-1820511@2x.png"
        group18222="/group-1822221@2x.png"
        path18813IconHeight="calc(100% - 31.3px)"
        onProfilClick={onProfilClick}
      />
      <img
        className="ipek-page-6-item"
        alt=""
        src="/group-1974211@2x.png"
        onClick={onGroupIconClick}
      />
      <img
        className="ipek-page-6-inner"
        alt=""
        src="/group-197431@2x.png"
        onClick={onGroupIcon1Click}
      />
      <div className="sonraki-sayfa5">Sonraki Sayfa</div>
      <div className="nceki-sayfa4">Önceki Sayfa</div>
      <div className="sesi-a5">Sesi Aç</div>
      <div className="szlk5">Sözlük</div>
      <div className="ipek-page-6-inner1">
        <div className="group-parent9">
          <img className="group-child22" alt="" src="/group-257.svg" />
          <div className="group-parent9">
            <div className="group-parent9">
              <img className="group-child23" alt="" src="/group-262.svg" />
              <img className="path-397-icon5" alt="" src="/path-397.svg" />
              <img className="group-child24" alt="" src="/group-265.svg" />
              <img className="path-399-icon5" alt="" src="/path-399.svg" />
              <img className="path-400-icon5" alt="" src="/path-400.svg" />
              <img className="path-401-icon5" alt="" src="/path-401.svg" />
              <img
                className="group-child25"
                alt=""
                src="/rectangle-141@2x.png"
              />
              <img
                className="group-child26"
                alt=""
                src="/rectangle-142@2x.png"
              />
              <img className="path-402-icon5" alt="" src="/path-402.svg" />
              <img className="path-403-icon5" alt="" src="/path-403.svg" />
              <img className="path-404-icon5" alt="" src="/path-404.svg" />
              <audio className="path-4055" autoPlay controls>
                <source src="https://mobil.okumanya.com.tr/voice/ipek/ipek-ses-6.mp3" />
              </audio>
              <img className="path-406-icon5" alt="" src="/path-406.svg" />
              <img className="path-407-icon5" alt="" src="/path-407.svg" />
            </div>
          </div>
        </div>
      </div>
      <img className="ipek-page-6-child1" alt="" src="/group-197451@2x.png" />
      <div className="path-20190-parent1">
        <img className="path-20190-icon3" alt="" src="/path-20190.svg" />
        <div className="ama-pek-kardeiyle" data-animate-on-scroll>
          Ama İpek, kardeşiyle dargın olmaktan hiç hoşlanmaz. Çünkü kardeşimle
          konuşmadan bir saniye bile duramam. Kardeşim ne zaman bana küsse
          “Lütfen, hemen barışalım,” diye onu gıdıklarım. Çünkü dargın kalmak
          beni çok üzer.
        </div>
      </div>
    </div>
  );
};

export default IpekPage5;
