import { useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./FooterSection.css";

const FooterSection = ({
  className = "",
  group81,
  group209,
  group18205,
  group18222,
  path18813IconHeight,
  onProfilClick,
}) => {
  const path18813IconStyle = useMemo(() => {
    return {
      height: path18813IconHeight,
    };
  }, [path18813IconHeight]);

  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  return (
    <div className={`ipek-page-1-inner1 ${className}`}>
      <section className="path-18812-parent8" id="menu-bar">
        <img className="path-18812-icon11" alt="" src="/path-18812.svg" />
        <img
          className="path-18813-icon11"
          alt=""
          src="/path-188131.svg"
          style={path18813IconStyle}
        />
        <div className="group-parent44" onClick={onGroupContainerClick}>
          <img className="group-child163" alt="" src="/group-71.svg" />
          <img className="group-child164" alt="" src={group81} />
        </div>
        <div className="ana-sayfa11">Ana Sayfa</div>
        <a className="profil12" onClick={onProfilClick}>
          <img className="group-child164" alt="" src={group209} />
        </a>
        <img className="group-child165" alt="" src={group18205} />
        <img className="group-child166" alt="" src={group18222} />
      </section>
    </div>
  );
};

FooterSection.propTypes = {
  className: PropTypes.string,
  group81: PropTypes.string,
  group209: PropTypes.string,
  group18205: PropTypes.string,
  group18222: PropTypes.string,

  /** Style props */
  path18813IconHeight: PropTypes.any,

  /** Action props */
  onProfilClick: PropTypes.func,
};

export default FooterSection;
