import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/FooterSection";
import "./IpekPage8.css";

const IpekPage8 = () => {
  const navigate = useNavigate();

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIconClick = useCallback(() => {
    navigate("/ipekpage10");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/ipekpage8");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="ipek-page-9">
      <img className="ipek-page-9-child" alt="" src="/group-138148@2x.png" />
      <div className="component-1-9">
        <div className="sayfa-913">Sayfa: 9/13</div>
        <img
          className="component-1-9-child"
          alt=""
          src="/group-183982@2x.png"
        />
      </div>
      <FooterSection
        group81="/group-81@2x.png"
        group209="/group-20941@2x.png"
        group18205="/group-1820521@2x.png"
        group18222="/group-1822231@2x.png"
        path18813IconHeight="calc(100% - 31.3px)"
        onProfilClick={onProfilClick}
      />
      <img
        className="ipek-page-9-item"
        alt=""
        src="/group-1974221@2x.png"
        onClick={onGroupIconClick}
      />
      <img
        className="ipek-page-9-inner"
        alt=""
        src="/group-197432@2x.png"
        onClick={onGroupIcon1Click}
      />
      <div className="sonraki-sayfa8">Sonraki Sayfa</div>
      <div className="nceki-sayfa7">Önceki Sayfa</div>
      <div className="sesi-a8">Sesi Aç</div>
      <div className="szlk8">Sözlük</div>
      <div className="ipek-page-9-inner1">
        <div className="group-parent15">
          <img className="group-child37" alt="" src="/group-257.svg" />
          <div className="group-parent15">
            <div className="group-parent15">
              <img className="group-child38" alt="" src="/group-262.svg" />
              <img className="path-397-icon8" alt="" src="/path-397.svg" />
              <img className="group-child39" alt="" src="/group-265.svg" />
              <img className="path-399-icon8" alt="" src="/path-399.svg" />
              <img className="path-400-icon8" alt="" src="/path-400.svg" />
              <img className="path-401-icon8" alt="" src="/path-401.svg" />
              <img
                className="group-child40"
                alt=""
                src="/rectangle-141@2x.png"
              />
              <img
                className="group-child41"
                alt=""
                src="/rectangle-142@2x.png"
              />
              <img className="path-402-icon8" alt="" src="/path-402.svg" />
              <img className="path-403-icon8" alt="" src="/path-403.svg" />
              <img className="path-404-icon8" alt="" src="/path-404.svg" />
              <audio className="path-4058" autoPlay controls>
                <source src="https://mobil.okumanya.com.tr/voice/ipek/ipek-ses-9.mp3" />
              </audio>
              <img className="path-406-icon8" alt="" src="/path-406.svg" />
              <img className="path-407-icon8" alt="" src="/path-407.svg" />
            </div>
          </div>
        </div>
      </div>
      <img className="ipek-page-9-child1" alt="" src="/group-197452@2x.png" />
      <div className="path-20190-parent4">
        <img className="path-20190-icon6" alt="" src="/path-20190.svg" />
        <div className="pek-arkadalarna-hediye" data-animate-on-scroll>
          İpek, arkadaşlarına hediye almaktan çok hoşlanır. Arkadaşlarıma değer
          veririm. Her birini neyin mutlu edeceğini çok iyi bilirim. Onları
          mutlu görmek, beni de mutlu eder.
        </div>
      </div>
    </div>
  );
};

export default IpekPage8;
