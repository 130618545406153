import { useEffect } from "react";
import "./GroupComponent.css";

const GroupComponent = ({ className = "" }) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className={`group-parent45 ${className}`}>
      <div className="okuduum-kitaplar-parent" data-animate-on-scroll>
        <div className="okuduum-kitaplar">
          <p className="okuduum">Okuduğum</p>
          <p className="okuduum">Kitaplar</p>
        </div>
        <div className="div13">20</div>
        <img className="group-child167" alt="" src="/group-13651.svg" />
      </div>
      <div className="parent" data-animate-on-scroll>
        <div className="div14">45</div>
        <div className="tamamlanan-sorumluluklar">
          <p className="okuduum">Tamamlanan</p>
          <p className="okuduum">Sorumluluklar</p>
        </div>
        <img className="group-child168" alt="" src="/group-13649.svg" />
      </div>
      <div className="group" data-animate-on-scroll>
        <div className="div15">5500</div>
        <div className="topladm-puan">
          <p className="okuduum">Topladığım</p>
          <p className="okuduum">Puan</p>
        </div>
        <img className="group-child169" alt="" src="/group-13648@2x.png" />
      </div>
      <div className="dinlediim-kitaplar-parent" data-animate-on-scroll>
        <div className="dinlediim-kitaplar">
          <p className="okuduum">Dinlediğim</p>
          <p className="okuduum">Kitaplar</p>
        </div>
        <div className="div13">15</div>
        <img className="group-child170" alt="" src="/group-19729.svg" />
      </div>
    </div>
  );
};

GroupComponent.propTypes = {
  className: PropTypes.string,
};

export default GroupComponent;
