import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/FooterSection";
import "./IpekPage12.css";

const IpekPage12 = () => {
  const navigate = useNavigate();

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIconClick = useCallback(() => {
    navigate("/ipekpage12");
  }, [navigate]);

  const onGroupIcon1Click = useCallback(() => {
    navigate("/ipekpage10");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="ipek-page-1-1">
      <img className="ipek-page-1-1-child" alt="" src="/group-1381410@2x.png" />
      <div className="component-1-11">
        <div className="sayfa-1113">Sayfa: 11/13</div>
        <img
          className="component-1-11-child"
          alt=""
          src="/group-183983@2x.png"
        />
      </div>
      <FooterSection
        group81="/group-811@2x.png"
        group209="/group-20941@2x.png"
        group18205="/group-1820521@2x.png"
        group18222="/group-1822241@2x.png"
        path18813IconHeight="calc(100% - 31.3px)"
        onProfilClick={onProfilClick}
      />
      <img
        className="ipek-page-1-1-item"
        alt=""
        src="/group-1974241@2x.png"
        onClick={onGroupIconClick}
      />
      <img
        className="ipek-page-1-1-inner"
        alt=""
        src="/group-197433@2x.png"
        onClick={onGroupIcon1Click}
      />
      <div className="sonraki-sayfa10">Sonraki Sayfa</div>
      <div className="nceki-sayfa9">Önceki Sayfa</div>
      <div className="sesi-a10">Sesi Aç</div>
      <div className="szlk10">Sözlük</div>
      <div className="ipek-page-1-1-inner1">
        <div className="group-parent19">
          <img className="group-child47" alt="" src="/group-257.svg" />
          <div className="group-parent19">
            <div className="group-parent19">
              <img className="group-child48" alt="" src="/group-262.svg" />
              <img className="path-397-icon10" alt="" src="/path-397.svg" />
              <img className="group-child49" alt="" src="/group-265.svg" />
              <img className="path-399-icon10" alt="" src="/path-399.svg" />
              <img className="path-400-icon10" alt="" src="/path-400.svg" />
              <img className="path-401-icon10" alt="" src="/path-401.svg" />
              <img
                className="group-child50"
                alt=""
                src="/rectangle-141@2x.png"
              />
              <img
                className="group-child51"
                alt=""
                src="/rectangle-142@2x.png"
              />
              <img className="path-402-icon10" alt="" src="/path-402.svg" />
              <img className="path-403-icon10" alt="" src="/path-403.svg" />
              <img className="path-404-icon10" alt="" src="/path-404.svg" />
              <audio className="path-40510" autoPlay controls>
                <source src="https://mobil.okumanya.com.tr/voice/ipek/ipek-ses-11.mp3" />
              </audio>
              <img className="path-406-icon10" alt="" src="/path-406.svg" />
              <img className="path-407-icon10" alt="" src="/path-407.svg" />
            </div>
          </div>
        </div>
      </div>
      <img className="ipek-page-1-1-child1" alt="" src="/group-197452@2x.png" />
      <div className="path-20190-parent6">
        <img className="path-20190-icon8" alt="" src="/path-20190.svg" />
        <div className="pek-dedesiyle-bahede" data-animate-on-scroll>
          İpek, dedesiyle bahçede çalışmaktan çok hoşlanır. Çünkü dedem, benim
          en yakın arkadaşımdır. Yaz tatillerini dedemle anneannemin evinde
          geçirmeye bayılırım. Dedemin çok güzel bir bahçesi var. Bahçesinde
          elma ve kayısı ağaçları var. Dedemle birlikte bahçe sulamayı ve
          ağaçtan meyve toplamayı çok seviyorum.
        </div>
      </div>
    </div>
  );
};

export default IpekPage12;
