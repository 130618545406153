import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/FooterSection";
import "./Aile.css";

const Aile = () => {
  const navigate = useNavigate();

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  const onGroupIconClick = useCallback(() => {
    navigate("/aile12");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="aile-11">
      <img className="aile-11-icon" alt="" src="/aile11@2x.png" />
      <div className="component-1-14">
        <div className="sayfa-114">Sayfa: 1/14</div>
        <img
          className="component-1-14-child"
          alt=""
          src="/group-18398@2x.png"
        />
      </div>
      <FooterSection
        group81="/group-81@2x.png"
        group209="/group-209@2x.png"
        group18205="/group-18205@2x.png"
        group18222="/group-18222@2x.png"
        path18813IconHeight="calc(100% - 31.2px)"
        onProfilClick={onProfilClick}
      />
      <img
        className="aile-11-child"
        alt=""
        src="/group-19742@2x.png"
        onClick={onGroupIconClick}
      />
      <div className="sonraki-sayfa12">Sonraki Sayfa</div>
      <div className="sesi-a13">Sesi Aç</div>
      <div className="szlk13">Sözlük</div>
      <img className="aile-11-item" alt="" src="/group-19744@2x.png" />
      <img className="aile-11-inner" alt="" src="/group-19745@2x.png" />
      <div className="path-20190-parent9">
        <img className="path-20190-icon11" alt="" src="/path-20190.svg" />
        <div className="benim-adm-atlas" data-animate-on-scroll>
          Benim adım Atlas. Tamı tamına yedi yaşındayım. Ne kadar büyük olduğumu
          düşünebiliyor musunuz? Bence düşünemiyorsunuz. Yedi tane üç yüz altmış
          beşi toplamak kadar zor bunu düşünmek. Ama merak etmeyin! Size
          kendimi, ailemi ve evimi anlatınca bana hak vereceksiniz.
        </div>
      </div>
    </div>
  );
};

export default Aile;
