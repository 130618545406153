import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./GroupComponent1.css";

const GroupComponent1 = ({ className = "" }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onRectangleButtonClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  const onGRYAPClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  return (
    <div className={`kullanc-girii-parent ${className}`} data-animate-on-scroll>
      <div className="kullanc-girii">Kullanıcı Girişi</div>
      <div className="bir-hesabn-var">Bir Hesabın var mı;?</div>
      <div className="beni-hatrla">Beni Hatırla</div>
      <div className="ifremi-unuttum">Şifremi Unuttum</div>
      <div className="group-child145" />
      <button
        className="rectangle-button"
        onClick={onRectangleButtonClick}
        data-animate-on-scroll
      />
      <div className="group-child146" />
      <div className="kullanc-ad">Kullanıcı Adı</div>
      <img className="group-child147" alt="" src="/group-1943.svg" />
      <div className="group-child148" />
      <a className="gr-yap" onClick={onGRYAPClick}>
        GİRİŞ YAP
      </a>
      <a className="kaydol-container">
        <div className="kaydol1">Kaydol</div>
      </a>
      <div className="path-3514-parent">
        <img className="path-3514-icon" alt="" src="/path-3514.svg" />
        <img className="path-3515-icon" alt="" src="/path-3515.svg" />
        <img className="group-child149" alt="" src="/group-1946.svg" />
        <div className="group-child150" />
        <div className="group-child150" />
        <div className="facebook-ile-giri">Facebook ile Giriş Yap</div>
      </div>
      <div className="path-3514-group">
        <img className="path-3514-icon" alt="" src="/path-35141.svg" />
        <img className="group-child149" alt="" src="/group-1946.svg" />
        <div className="group-child150" />
        <div className="group-child150" />
        <div className="google-ile-giri">Google ile Giriş Yap</div>
        <img className="group-child155" alt="" src="/group-7650.svg" />
      </div>
      <img className="group-child156" alt="" src="/group-1949.svg" />
      <img className="group-child157" alt="" src="/group-1951.svg" />
      <img className="group-child158" alt="" src="/group-1953.svg" />
    </div>
  );
};

GroupComponent1.propTypes = {
  className: PropTypes.string,
};

export default GroupComponent1;
